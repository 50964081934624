import React from 'react'
import { Assets } from '../Assets';
function BookingConfirmation({ handleClose, show }) {
    const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';
  return (
    <div className={ showHideClassName2 }>
    <section className="popup">
        <section className="popupInner">
            <img src={ Assets.booking_confimation } alt="booking_confimation" style={{ margin:"50px 0 50px 80px" }} />
            <div className="popupDownloadAppHeading">
                <div className="checkoutLoginPhoneNumberHeading">
                Your booking is confirmed
                </div>
                <p className="text-center">Confirmation email and SMS have been sent to your registered details.</p>
                <div className="downloadAppPopupContent">
                    <div className="appScanner">
                        <img src={ Assets.appQr } alt="Periodpal Mobile App" />
                        <div className="appDownloadTextContent">
                            <h3>Download Our App</h3>
                            <p>Discover the power of our menstrual app designed with you in mind.</p>
                            <div className="appDownloadLinks">
                                <a href="https://apps.apple.com/us/app/period-pal-by-laiqa/id1591718639">
                                    <img src={ Assets.appStore } alt="Playstore" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.laiqa">
                                    <img src={ Assets.playStore } alt="Playstore" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="rfwPopupCloseButton">
                <button onClick={ handleClose }>
                    <img src={ Assets.popupCloseCross } alt="Close" />
                </button>
            </div>
        </section>
    </section>
</div>
  )
}

export default BookingConfirmation
