import { Assets } from "../Assets";
import { Link } from "react-router-dom";
const MainContentDietPlans = () => {
    return (
      <div className="laiqaMainContentBg selfPrognosisgridbx">
        <div className="laiqaMainContentWrapper">
          <div className="laiqaMainContentMidLayer">
            <img
              alt="selfprognos"
              src={Assets.selfprognosisbg}
              id="landingPageBannerImg1"
            />
            {/* <img src={ Assets.laiqaMainContentBgImgResp } id="landingPageBannerImg2" /> */}
          </div>
          <div className="grid_selfprognos_content">
            <div className="grid_selfprognos_contentbx">
            <h1>Do you know where you <span>Stand on the wellness scale?</span></h1>
            <Link to="https://apps.apple.com/us/app/period-pal-by-laiqa/id1591718639">
                  <img
                    className="footerDownloadLaiqa"
                    src={Assets.App_store}
                    alt="App store"
                  />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.laiqa&pli=1">
                  <img
                    className="footerDownloadLaiqa"
                    src={Assets.play_store}
                    alt="play store"
                  />
                  </Link>
            </div>
          </div>
        </div>
        <div className="main_grid">
          <div className="parent_grid">
            <div className="child_grid">
              <img
                alt="women vesting their faith in us since 2019"
                src={Assets.Group_163701}
              />
              <h5>100k +</h5>
              <p>women vesting their faith in us since 2019</p>
            </div>
            <div className="child_grid">
              <img
                alt="Ratings across all services"
                src={Assets.Group_1637012}
              />
              <h5>4.75 / 5</h5>
              <p>Ratings across all services</p>
            </div>
            <div className="child_grid">
              <img
                alt="Health profile tracked across 90 parameters"
                src={Assets.Group_1637013}
              />
              <h5>90 Parameters</h5>
              <p>Health profile tracked across 90 parameters</p>
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default MainContentDietPlans;