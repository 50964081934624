import { Assets } from '../Assets';

import PopupRegisterForWorkshop from './PopupRegisterForWorkshop'
import PopupRegisterSuccessful from './PopupRegisterForWorkshopSuccessful'
import {PostAPI, GetAPI, PatchAPI, DeleteAPI} from '../utility/APiCall' 

import { useState, useEffect } from 'react';

const WorkshopIntro = ({ onActiveChange }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [showWorkshopPopup, setShowWorkshopPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [activeWorkshop, setActiveWorkshop] = useState(null);

    const openWorkshopPopup = () => {
        if (activeWorkshop){
            setShowWorkshopPopup(true);
        }
        else{
            alert("No workshops at the moment. Stay tuned for more events!")
        }
    };
    
    const closeWorkshopPopup = () => {
        setShowWorkshopPopup(false);
    };
    
    const openSuccessPopup = () => {
        setShowSuccessPopup(true);
        closeWorkshopPopup();
    };
    
    const closeSuccessPopup = () => {
        setShowSuccessPopup(false);
    };

    const workshopInfo = () => {
        const url = `/web/workshops`;
        GetAPI(url, (res) => {
            if (res.status === 1) {
                const workshops = res.data;
                const activeWorkshopData = workshops.find((workshop) => workshop.workshop_status === 'Active');
                setActiveWorkshop(activeWorkshopData);
                onActiveChange(true);
                console.log('Active Workshop:', activeWorkshopData);
              } else {
                console.error('Error fetching workshop details:', res.message);
              }
        });
    }

    useEffect(() => {
        workshopInfo();
    }, []);

    return(
        <div className="workshopIntroContent">
            <PopupRegisterForWorkshop 
                show={showWorkshopPopup} 
                handleClose={closeWorkshopPopup} 
                openSuccessPopup={openSuccessPopup} 
                workshopId={ activeWorkshop?.id }
                workshopDate={ activeWorkshop?.workshop_date }
                workshopTime={ activeWorkshop?.workshop_time }
                isWorkshopActive={ activeWorkshop } />
            <PopupRegisterSuccessful 
                show={showSuccessPopup}
                handleClose={closeSuccessPopup} />     
            <div className="whyOnlyUsImageContent">
                <div className="workshopIntroImageWrapper">
                    <img alt='' src={ Assets.workshopIntroImage } />
                </div>
                <div className="workshopIntroTextContent">
                    <p className="workshopIntroTextHeading">Workshop</p>
                    {/* <p className='womenWellnessTextHeading2'>Laiqa Provides Best <br /><b>Experience with the Best</b></p> */}
                     <p className='womenWellnessTextHeading2'>Experience the Best With <br /><b>Laiqa!</b></p>
                     <p className="workshopIntroArticle">Join our expertly crafted workshops led by specialists to receive precise and reliable information on health conditions, treatments, wellness plans, and much more. Attending these workshops will not only expand your knowledge but also provide valuable hands-on experience. Register now and secure your spot right away!</p>
                     <button id="workshopIntroButton"
                    className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={ openWorkshopPopup }
                    >
                    Register Now
                    {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpRightBlack } /> }
                  </button>
                </div>
            </div>
        </div>
    )
}

export default WorkshopIntro;