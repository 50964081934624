import NavbarHomePage from "./Home/NavbarHomePage";
import MainContentDirectPlans from "./DirectPlans/MainContentDirectPlans";
import OurPlansDirectPlans from "./DirectPlans/OurPlansDirectPlans";
import ScheduleCallDirectPlans from "./DirectPlans/ScheduleCallDirectPlans";
import WorkshopIntro from "./Workshop/WorkshopIntro";
import UnderstandHowDirectPlans from "./DirectPlans/UnderstandHowDirectPlans";
import WhyChooseUsDirectPlans from "./DirectPlans/WhyChooseUsDirectPlans";
import OurDoctorsDirectPlans from "./Teleconsultation/OurDoctorsTeleconsultation";
import FAQDirectPlans from "./Teleconsultation/FAQTeleconsultation";
import FeaturedAndFooter from "./Home/FeaturedAndFooter";

const DirectPlans = () => {
    return(
        <div>
            <NavbarHomePage />
            <MainContentDirectPlans />
            <OurPlansDirectPlans />
            <ScheduleCallDirectPlans />
            <div className="workshopIntroContentDirectPlans">
                <WorkshopIntro />
            </div>
            <UnderstandHowDirectPlans />
            <div className="whyChooseUsDirectPlans">
                <WhyChooseUsDirectPlans />
            </div>
            <div className="ourDoctorsDirectPlans">
                <OurDoctorsDirectPlans />
            </div>
            <div className="FAQDirectPlans">
                <FAQDirectPlans />
            </div>
            <div className="FeaturedAndFooterDirectPlans">
                <FeaturedAndFooter />
            </div>
        </div>
    )
}

export default DirectPlans;