import { Assets } from "./Assets";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw24 from "./Kyw24/ContentFrameKyw24";
import FooterKyw from "./Kyw1/FooterKyw";

const KnowYourWellness24 = () => {
    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            <NavbarKyw />
            <MainContentKyw />
            <ContentFrameKyw24 />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness24;