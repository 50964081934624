import { Assets } from "../Assets"

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ContentFrameKyw20 = (props) => {

    const navigate = useNavigate();

    const {data, setData, errors, setErrors, questionId = [], handleRadioChange, inputValue, answers, 
        getSleepData, setSleepData, message, setMessage, getSleepscaleData, handleFormSubmit, 
        sliderValue, setSleepscaleData, isChecked, setIsChecked, formSubmitted, setFormSubmitted} = props;

    const [isHovered, setIsHovered] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        // if (!isChecked) {
        //     alert("Please select an option"); // Show an alert message
        //     setShowLoginPopup(false);
        //     return; // Exit the function if no option is selected
        // }
        // If an option is selected, proceed with form submission
        setShowLoginPopup(true); // Set showLoginPopup to true
        setFormSubmitted(true); // Update the state to mark form as submitted
        props.handleFormSubmit(e); // Call the parent's form submission function
        //navigate('/selfprognosis/know-your-wellness/occupational-stress-result');
        // console.log('datacheck',data);
    };

    const clickNext = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        navigate('/selfprognosis/know-your-wellness/activity-assessment');
        //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
    }

    const [visible, setVisible] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
   

    const handleClose = () => {
        setIsOpen(false);
    };

    const openLoginPopup = () => {
        //setShowLoginPopup(true);
        //alert('Check');
        setFormSubmitted(true); // Update the state to mark form as submitted
    };
    
    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    //const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';
    const showHideClassName2 = showLoginPopup
    ? 'loginPopupBg result display-block'
    : 'loginPopupBg result display-none';

    const user_name = sessionStorage.getItem("user_name");

    const [currentStep, setCurrentStep] = useState(1);
    const nextStep = () => {
      const steps = {
          1: [1, 2, 3],
          2: [4, 5, 6],
          3: [7, 8]
      };
  
      const unansweredQuestions = questionId
          .filter(item => steps[currentStep].includes(item.question_id))
          .filter(item => !data.answer.some(ans => ans.question_id === item.question_id));
  
      if (unansweredQuestions.length > 0) {
          const newErrors = {};
          unansweredQuestions.forEach(item => {
              newErrors[item.question_id] = "Please choose at least one.";
          });
          props.setErrors(newErrors);
          return;
      }
  
      props.setErrors({});
      setCurrentStep(prev => prev + 1);
  };
  
  const renderQuestions = () => {
      if (!questionId || !Array.isArray(questionId)) {
          return <div>No questions available</div>;
      }
  
      const steps = {
        1: [1, 2, 3],
        2: [4, 5, 6],
        3: [7, 8]
      };
  
      const questionsToRender = questionId.filter(item => steps[currentStep].includes(item.question_id));
  
      return questionsToRender.map((item, index) => {
          const filteredOptions = item.options.map(option => {
              const optionText = option.option.toLowerCase();
              if (["never", "rarely", "sometimes", "often", "very often"].includes(optionText)) {
                  return { ...option, option: optionText.charAt(0).toUpperCase() + optionText.slice(1) };
              }
              return option;
          });
  
          return (
              <div key={index} className="kyw1MainContentHeading">
                  <h1 style={{ marginTop: '0px' }}>{item.question}</h1>
                  {filteredOptions.map((option, j) => (
                      <div key={j} className="flex items-center mr-4 mb-4 radio">
                          <input
                              id={`${item.question_id}_${option.id}`}
                              value={option.id}
                              type="radio"
                              onChange={() => handleRadioChange(item.question_id, option.id)}
                              checked={inputValue[item.question_id] === option.id}
                              name={`radio${item.question_id}`}
                              className="hidden"
                          />
                          <label htmlFor={`${item.question_id}_${option.id}`} className="flex items-center cursor-pointer">
                              <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                              {option.option}
                          </label>
                      </div>
                  ))}
                  {errors[item.question_id] && <div className="error">{errors[item.question_id]}</div>}
              </div>
          );
      });
  };

    const getBmiCategoryColor = () => {
        if (getSleepData <= 15) {
            return '#D27060';
        }
        else if (getSleepData > 15 && getSleepData <= 20) {
            return '#FC9403';
        }
        else if (getSleepData > 20 && getSleepData <= 25) {
            return '#FCF803';
        }
        else if (getSleepData > 25 && getSleepData <= 30) {
            return '#CCFF00';
        }
        else {
            return '#7AB375';
        }
    };

    const bmiCategoryColor = getBmiCategoryColor();
    
    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                <div className='absoluteStage'>
                <p className="mb-0" style={{marginBottom:'0px'}}>Step 4</p>
                <p>Stress</p>
                </div>
                <img src={ Assets.kyw14Bg2 } alt="Stress" />
                <div className='absolute'>
                <p>57 percent of women reported feeling burnout out as a result of work stress, compared to 48 percent of men.</p>
                </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                <div className="progressCirclesContainer">
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 4 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleTwoForth" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw6MainContent kyw1MainContent">
                {formSubmitted ? (
                        <>
						 
                        <div className="kyw4MainContentHeading">
                        <p>Hey {user_name},</p>
                    </div>
                    <div className="kyw4MainContentSubHeading">
                        <p>Thanks for taking the Occupational Stress assessment</p>
                    </div>
                    <div className="bmiResultWrapper">
                    <div className="bmiResultHeading">
                            <p>Your Occupational stress score is</p>
                        </div>
                        <div className="bmiResult">
                            <div className="bmiResultFloatResponse">
                                <input
                                    type="text"
                                    id="bmiHeightInputId"
                                    value={getSleepData}
                                    maxLength={ 4 }
                                />
                            </div>
                            <div className="bmiResultStringResponse">
                                <div style={{ marginTop: '10px', color: bmiCategoryColor }}>
                                    {bmiCategoryColor === '#D27060' && 'Potentially High'}
                                    {bmiCategoryColor === '#FC9403' && 'Severe Stress'}
                                    {bmiCategoryColor === '#FCF803' && 'Moderate Stress'}
                                    {bmiCategoryColor === '#CCFF00' && 'Fairly Low Stress'}
                                    {bmiCategoryColor === '#7AB375' && 'Relatively Calm'}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="occuStressSliderContainer">
                                <input
                                    type="range"
                                    id="slider"
                                    className="bmiSlider"
                                    name="slider"
                                    min="0"
                                    max="40"
                                    value={getSleepData}
                                    // onChange={ handleSliderChange }
                                />
                            </div>
                        </div>

                        <div className="bmiLogoBr">
                            <img alt="" src={ Assets.stressLogo } />
                        </div>
                    </div>
                    
                    <div className="bmiCategories">
                        <ul>
                         {getSleepscaleData && getSleepscaleData.map(item => (
                        <li className={item.selected ? 'selected' : ''} key={item.id}>
                        {item.description}
                        </li>
                        ))}
                        </ul>
                    </div>
                    
                    <div className="kywNextLinkWrapper">
                        <Link className="backLink" to="" onClick={() => {
                    navigate(-1);
                    }}>
                            <img src={ Assets.backArrow } alt="Back" />
                            Back
                        </Link>
                            <button type="button" onClick={clickNext} id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                >
                                Next
                                { isHovered ? <img alt="" src={ Assets.arrowUpRight } /> : <img alt="" src={ Assets.arrowUpRightBlack } /> }
                            </button>
                    </div>
						  </>
                  ) : (
                    <>
					<form onSubmit={handleSubmit}>
                     {renderQuestions()}
                      <div className="kywNextLinkWrapper">
                        <Link className="backLink" to="" onClick={() => {
                            navigate(-1);
                            }}>
                            <img src={ Assets.backArrow } alt="Back" />
                            Back
                        </Link>
                        {currentStep < 3 && (
                        <button
                            type="button"
                            onClick={nextStep}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            id="kywNextButtonSmall">
                            Next
                            {isHovered ? (
                            <img alt="" src={Assets.arrowUpRight} />
                            ) : (
                            <img alt="" src={Assets.arrowUpRightBlack} />
                            )}
                        </button>
                    )}
                    {currentStep === 3 && (
                    <button type="submit" id="kywNextButtonSmall"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    //onClick={openLoginPopup}
                    >
                    Next
                    {isHovered ? <img alt="" src={ Assets.arrowUpRight } /> : <img alt="" src={ Assets.arrowUpRightBlack } /> }
                    </button>
                    )}
                    </div>
                  </form>
					</>
                )}
                </div>
            </div>
        </div>
    )
}

export default ContentFrameKyw20;