import React,{useState, useEffect} from "react";
import { Assets } from "./Assets";
import { useNavigate } from "react-router-dom";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw8 from "./Kyw8/ContentFrameKyw8";
import FooterKyw from "./Kyw1/FooterKyw";
// import NavMenu from "./navbar/NavMenu";
import { PostAPI, GetAPI } from "./utility/APiCall";
import useCookie from "./cookies/Cookie";
import Cookies from 'js-cookie';
import NavbarHomePage from "./Home/NavbarHomePage";

const KnowYourWellness7 = () => {

    const navigate = useNavigate();

    const [questionId, setQuestionId] = useState()

    const getCatId = sessionStorage.getItem('getActivityID');
    console.log('getCatId', getCatId);

    const [category, setCategory] = useState({
        category_id:getCatId,
    });


    const get_questionnaires = (e) => {
         const categoryData = new FormData();
         const getCatId = sessionStorage.getItem('getActivityID');
         categoryData.append('category_id', getCatId);
 
        // navigate('/self-prognosis/know-your-wellness/bmi-result');
 
         PostAPI({ url: '/get_onboarding_questionnaires', data: categoryData }, (res) => {
            // alert('check')
             console.log('Questions', res?.data);
             const question_id = res?.data[0];
             console.log('question_id',question_id);
             setQuestionId(question_id);
         });
     }

useEffect(()=>{
    get_questionnaires();
},[])

// const [data, setData] = useState({
//     user_id:'',
//     category_id:getCatId,
//     answer: [],                                            
//     journey_id:'0',
//     plan_data_id:'0',
//     month:'0',
// });

// const [sliderValue, setSliderValue] = useState(0);
 // Assuming you have a state variable like this
 const [sliderValue, setSliderValues] = useState({
    active: 0,
    moderately: 0,
    low: 0,
    sedentary: 0,
  });
  
  const setSliderValue = (activityLevel, value) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [activityLevel]: value,
    }));
  };
const [inputValue, setInputValue] = useState(0);

// const [data, setData] = useState({
//     user_id: '',
//     category_id: getCatId,
//     activities: {
//       active: { inputValue: '', sliderValue: 0 },
//       moderately: { inputValue: '', sliderValue: 0 },
//       low: { inputValue: '', sliderValue: 0 },
//       sedentary: { inputValue: '', sliderValue: 0 },
//     },
//     journey_id: '0',
//     plan_data_id: '0',
//     month: '0',
//   });

    const [getPlan, setGetPlan] = useState();
    const [journeyId, setJourneyId] =useState();
    const [planDataId, setplanDataId] =useState();
    const [month, setMonth] = useState();


    console.log('getPlangetPlan', getPlan);

    const getPlandetails = () => {

      //const getUID = sessionStorage.getItem('getUID')
      const getUID = Cookies.get('getUID');
      const userId = getUID;
        const url = `/v2_get_user_plan_details/${userId}`;

        GetAPI(url, (res)=>{

                    const plan_data_id = res?.data?.plan_data_id;
                    if (plan_data_id == null || plan_data_id == 0){
                    setplanDataId(0);
                    } else {
                    setplanDataId(plan_data_id);
                    }

                    const pre_journey_id = res?.data?.pre_journey_id;
                    if (pre_journey_id == null || pre_journey_id == 0){
                    setJourneyId(0);
                    } else {
                    setJourneyId(pre_journey_id);
                    }

                    const current_month = res?.data?.plan_data_id;
                    if (current_month == null || current_month == 0){
                    setMonth(0);
                    } else {
                    setMonth(current_month);
                    }
                const result = res?.data;
                setGetPlan(result);

        });

    }

    useEffect(()=>{
        getPlandetails();
    }, [])

const journey_id = sessionStorage.getItem('journey_id')

const [data, setData] = useState({
    user_id: '',
    category_id: getCatId,
    activities: {
      active: { inputValue: '', sliderValue: 0 },
      moderately: { inputValue: '', sliderValue: 0 },
      low: { inputValue: '', sliderValue: 0 },
      sedentary: { inputValue: '', sliderValue: 0 },
    },
    journey_id: journey_id,
    plan_data_id: '0',
    month: '0',
  });

  const handleSliderChange = (event, activityLevel) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setSliderValue(activityLevel, newValue);
      setInputValue(activityLevel, newValue);
  
      setData((prevData) => ({
        ...prevData,
        activities: {
          ...prevData.activities,
          [activityLevel]: {
            inputValue: newValue,
            sliderValue: newValue,
          },
        },
      }));
    }
  };
  
  const handleInputChange = (event, activityLevel) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setSliderValue(activityLevel, newValue);
      setInputValue(activityLevel, newValue);
  
      setData((prevData) => ({
        ...prevData,
        activities: {
          ...prevData.activities,
          [activityLevel]: {
            inputValue: newValue,
            sliderValue: newValue,
          },
        },
      }));
    } else {
      setSliderValue(activityLevel, 0);
      setInputValue(activityLevel, '');
  
      setData((prevData) => ({
        ...prevData,
        activities: {
          ...prevData.activities,
          [activityLevel]: {
            inputValue: '',
            sliderValue: 0,
          },
        },
      }));
    }
  };
  

const handleFormSubmit = (e) => {
    const questionData = new FormData();
    //const defaultAnswers = [98, 99, 100, 101];

    const defaultAnswers = [98, 99, 100, 101];

    const answer = [{
      question_id: questionId,
      answer: defaultAnswers.map(dayValue => ({
        answer: dayValue,
        day: inputValue,
      })),
    }];

    const getCatId = sessionStorage.getItem('getActivityID');
    const getUID = Cookies.get('getUID');
    //const getUID = sessionStorage.getItem('getUID')
    const userId = getUID;
    questionData.append('user_id', userId);
    questionData.append('category_id', getCatId);
    //questionData.append('answer', updatedData);
    questionData.append('answer', JSON.stringify(answer)); // Convert to JSON string
    questionData.append('journey_id', journey_id);
    questionData.append('plan_data_id', planDataId);
    questionData.append('month', month);
    
    console.log('FormData content:', questionData);

    // PostAPI({ url: '/add_onboarding_questionnaires_data', data: questionData }, (res) => {
    //     console.log('Questions 55', res?.data);
    //   }).catch((error) => {
    //     console.error('API Error:', error);
    //   });

}

const handleDataChange = (newData) => {
    setData(newData);
  };

    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img alt="" id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            {/* <NavbarKyw /> */}
            <NavbarHomePage />
            <MainContentKyw />
            <ContentFrameKyw8 
            questionId={questionId} setQuestionId={setQuestionId}
            inputValue={inputValue} 
            setInputValue={setInputValue} 
            sliderValue={sliderValue} 
            setSliderValue={setSliderValue} 
            data={data} 
            setData={setData} 
            handleFormSubmit={handleFormSubmit} 
            handleDataChange={handleDataChange} 
           // questionId={questionId} 
            handleInputChange={handleInputChange}
            handleSliderChange={handleSliderChange}
             />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness7;