import React, {useEffect} from 'react'
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import NavbarHomePage from './Home/NavbarHomePage';
import AccountMainContent from './Account/AccountMainContent';
import AccountDeletedSuccessfullyContent from './Account/AccountDeletedSuccessfullyContent';
import { useNavigate, useParams } from "react-router-dom";
import { GetAPI } from './utility/APiCall';

function DeleteMyAccountSuccess() {

  const param = useParams();

  // const getID = sessionStorage.getItem('dau_id'); 
  // console.log('Testing', getID);

  console.log('param>>',param.id);

  const id = param.id;

  const getUDID = ()=>{
  const url = `/v2_deleteUserAccount/${id}`;
  GetAPI(url, (res)=>{
  const getuserID = res;
  console.log('getuserID>>',getuserID);
  });
  }

useEffect(() => {
  getUDID();
  const hasReloaded = sessionStorage.getItem('hasReloaded');

  if (!hasReloaded) {
    // Reload the page only if it hasn't been reloaded before
    sessionStorage.setItem('hasReloaded', 'true');
    window.location.href = window.location.href;
  }
}, []);

  return (
    <div className="homePage myaccountpage">
            <NavbarHomePage />
            <AccountMainContent />
            <AccountDeletedSuccessfullyContent />
            <div className="blogFooter">
            <FeaturedAndFooter />
            </div>
        </div>
  )
}

export default DeleteMyAccountSuccess
