import { Assets } from '../Assets'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import PopupScheduleCall from '../ScheduleCallPopup/PopupScheduleCall'


const MainContentAboutUs = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    const [showScheduleCallPopup, setShowScheduleCallPopup] = useState(false);

    const openScheduleCallPopup = () => {
        setShowScheduleCallPopup(true);
    };
    
    const closeScheduleCallPopup = () => {
        setShowScheduleCallPopup(false);
    };

    return ( 
        <div className="laiqaMainContentBg">
            <PopupScheduleCall show={ showScheduleCallPopup } handleClose={ closeScheduleCallPopup } openLoginOTPPopup={ openScheduleCallPopup } />
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                <Link to="/">
                <img alt='logo' src={ Assets.mainContentAboutUS } id="landingPageBannerImg1" />
                {/* <img src={ Assets.laiqaMainContentBgImgResp } id="landingPageBannerImg2" /> */}
                </Link>
                </div>
                <div className="laiqaMainContentButtons">
                    <button id="midLayerButton"
                        className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        >
                        Book Now
                        {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpRightBlack } /> }
                    </button>
                    <div className="scheduleCallMainContent">
                        <div className='scheduleCallButtonWrapper'>
                            <button id="scheduleCallButtonHomePage"
                                className={ `${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}` }
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}
                                onClick={ openScheduleCallPopup }
                                >
                                {isHovered2 ? <img src={ Assets.phoneCallingWhite } /> : <img src={ Assets.phoneCalling } /> }
                            </button>
                        </div>
                        <div className="scheduleCallContent">
                            <p className="scheduleCallp1">Schedule Call</p>
                            <p className="scheduleCallp2">(+91) 73033 65564</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="breadcrumb">
                <div className="breadcrumbWrapper">
                    <div className="aboutUsBreadcrumb">
                        <ul>
                            <li><Link to="/">Home • </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/wellness-plans"> About us</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default MainContentAboutUs;