import React, { Component } from 'react';

import NavbarWellnessPlans from './WellnessPlans/NavbarWellnessPlans';
import MainContentWellnessPlansCheckoutLogin from './WellnessPlansCheckoutLogin/MainContentWellnessPlansCheckoutLogin';
import SelectedPlansWellnessPlansCheckoutLogin from './WellnessPlansCheckoutLogin/SelectedPlanWellnessPlansCheckoutLogin';
import OurDoctorsTeleconsultation from './Teleconsultation/OurDoctorsTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter';
import NavbarHomePage from './Home/NavbarHomePage';
class WellnessPlansCheckout extends Component {
    render() {
        return(
            <div className="wellnessPlansIndex">
                {/* <NavbarWellnessPlans /> */}
                <NavbarHomePage />
                <MainContentWellnessPlansCheckoutLogin />
                <SelectedPlansWellnessPlansCheckoutLogin />
                <div className="ourDoctorsCheckoutWrapper">
                    <OurDoctorsTeleconsultation />
                </div>
                <div className="wellnessFooter3">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default WellnessPlansCheckout;