import { Assets } from '../Assets';

import { useState } from 'react';
import { PostAPI } from '../utility/APiCall';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

const ScheduleCallDirectPlans = () => {

    const [isChecked, setIsChecked] = useState(true);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    
const userIdd = sessionStorage.getItem('getUID');
const [errors, setErrors] = useState({});
const initialData = {
  user_id: userIdd,
  full_name: "",
  email: "",
  mobile_number: "",
};
const [data, setData] = useState(initialData);

  
  const validateFields = (name, value) => {
    const fieldErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^[0-9]{10}$/;
    //pattern="/^-?\d+\.?\d*$/"

    if (name === undefined || name === 'full_name') {
      if (!data.full_name) {
        fieldErrors.full_name = 'Full name is required';
      }
    }
    if (name === undefined || name === 'email') {
      if (!data.email) {
        fieldErrors.email = 'Email is required';
      } else if (!emailPattern.test(data.email)) {
        fieldErrors.email = 'Invalid email address';
      }
    }
    if (name === undefined || name === 'mobile_number') {
      if (!data.mobile_number) {
        fieldErrors.mobile_number = 'Mobile number is required';
      } else if (!mobilePattern.test(data?.mobile_number)) {
          //fieldErrors.mobile_number = 'Mobile number must be exactly 10 digits';
        //fieldErrors.mobile_number = 'Mobile number must be exactly 10 digits';
      }
    }
    return fieldErrors;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   // Enforce numeric input for mobile_number
  //   if (name === 'mobile_number' && !/^[0-9]*$/.test(value)) {
  //     return; // Do nothing if the input is not numeric
  //   }

  //   // Trim value
  //   const trimmedValue = value.trim();

  //   // Enforce length limit for mobile number
  //   if (name === 'mobile_number' && trimmedValue.length > 10) {
  //     return; // Do nothing if length exceeds 10 digits
  //   }

  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: trimmedValue
  //   }));

  //   // Validate individual field
  //   const fieldErrors = validateFields(name, trimmedValue);

  //   setErrors((prevErrors) => {
  //     if (!Object.keys(fieldErrors).length) {
  //       const { [name]: _, ...rest } = prevErrors; // Remove specific error if the field is valid
  //       return rest;
  //     }
  //     return {
  //       ...prevErrors,
  //       ...fieldErrors
  //     };
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Enforce numeric input for mobile_number
    if (name === 'mobile_number' && !/^[0-9]*$/.test(value)) {
      return; // Do nothing if the input is not numeric
    }
    // Enforce length limit for mobile number
    if (name === 'mobile_number' && value.length > 10) {
      return; // Do nothing if length exceeds 10 digits
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Validate individual field
    const fieldErrors = validateFields(name, value);
    setErrors((prevErrors) => {
      if (!Object.keys(fieldErrors).length) {
        const { [name]: _, ...rest } = prevErrors; // Remove specific error if the field is valid
        return rest;
      }
      return {
        ...prevErrors,
        ...fieldErrors
      };
    });
  };

    const handleSubmit = (event) => {
      event.preventDefault();
      event.stopPropagation();
  
      const form = event.currentTarget;
      const validationErrors = validateFields();
      if (Object.keys(validationErrors).length === 0) {
        if (form.checkValidity()) {
          if (event.target.name === 'insert') {
           
            if (!userIdd) {
              // Show alert if user is not logged in
              alert('Please log in first to schedule callback.');
            } else {
              var url = `/web/schedule_callback`;
              const formatDate = new FormData();
              formatDate.append('user_id', userIdd);
              formatDate.append('full_name', data?.full_name);
              formatDate.append('email', data?.email);
              formatDate.append('mobile_number', data?.mobile_number);
              var params = { url, data: formatDate };
              console.log('params:', params);
              PostAPI(params, (res) => {
                  if (res.status == 1) {
                     console.log('API Response:', res);
                     toast.success(res?.message);
                    //setShowScheduleCallPopup(false);
                    //handleClose();
                    setData(initialData);
                    setTimeout(() => {
                      //navigate('/');
                      //setData();
                    }, 2000); // Adjust the delay time (in milliseconds) as needed
                  }
                });
            }
              
           
          }
        }
      } else {
        setErrors(validationErrors);
      }
    };




    return (
      <div className="scheduleCallDirectPlans">
        <div className="scheduleCallDpWrapper">
          <img src={Assets.scheduleCallBg} alt="Schedule a call" />
          <div className="scheduleCallDpContent">
            <div className="scheduleCallDpTextContent">
              <p className="scheduleCallDpHeading1">Safe And Secure</p>
              <p className="scheduleCallDpHeading2">I'm interested</p>
              <p className="scheduleCallDpHeading3">Can I have a call back?</p>
            </div>
            <div className="scdpFormInner">
              <div className="scdpFormHeading">
                <p>I'm interested, can I have a call back?</p>
              </div>
              <form
                onSubmit={handleSubmit}
                name={"insert"}
                className="registerForWorkshopForm"
              >
                <p id="corporateFormDesignation">Full Name*</p>
                <input
                  type="text"
                  autocomplete="off"
                  id="corporateFormDesignationInput"
                  // onChange={(e) =>
                  //   setData({ ...data, full_name: e.target.value })
                  // }
                  name='full_name'
                  onChange={handleInputChange}
                  value={data?.full_name}
                  placeholder="Enter your full name"
                />
                {errors.full_name && <p className="error">{errors.full_name}</p>}
                <div className="scdpFormEmailAndNumberInputs">
                <div className='cloum'>
                <p id="corporateFormName">Email</p>
                  <input
                    type="text"
                    autocomplete="off"
                    id="scdpFormEmailInput"
                    // onChange={(e) =>
                    //   setData({ ...data, email: e.target.value })
                    // }
                    name='email'
                    onChange={handleInputChange}
                    value={data?.email}
                    placeholder="Enter your email"
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className='cloum'>
                <p id="corporateFormCity" style={{ marginLeft: "247px" }}>
                    Mobile Number*
                  </p>
                  <input
                    type="tel"
                    autocomplete="off"
                    id="scdpFormNumberInput"
                    // onChange={(e) =>
                    //   setData({ ...data, mobile_number: e.target.value })
                    // }
                    name='mobile_number'
                    onChange={handleInputChange}
                    value={data?.mobile_number}
                    placeholder="Enter your mobile number"
                    pattern="[0-9]{10}"
                    
                  />
                  {errors.mobile_number && <p className="error">{errors.mobile_number}</p>}
                </div>
                </div>
                <div
                  className={`rdwPopupCheck ${
                    isChecked ? "rdwPopupChecked" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={toggleCheckbox}
                  />
                  <span className="checkmarkEmpty"></span>
                  <img
                    alt=""
                    src={Assets.check}
                    className="checkmarkFilled"
                  ></img>
                  <p>
                    I authorise LAIQA representatives to call and whatsapp me
                  </p>
                </div>
                <button type="submit" id="rfwRegisterFormButton" onClick="">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
}

export default ScheduleCallDirectPlans;