import React, { Component } from 'react';

import NavbarContactUsPage from './Home/NavbarHomePage';
import MainContentContactUsPage from './ContactUs/MainContentContactUs';
import GetInTouchContactUs from './ContactUs/GetInTouchContactUs';
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import NavbarHomePage from './Home/NavbarHomePage';

class ContactUs extends Component {
    render() {
        return(
            <div className="homePage">
                {/* <NavbarContactUsPage /> */}
                <NavbarHomePage />
                <MainContentContactUsPage />
                <GetInTouchContactUs />
                <div className="contactUsFooter">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default ContactUs;