import React from 'react'
import { useState, useEffect } from 'react';
import { Assets } from '../Assets'
import { PostAPI, GetAPI } from "../utility/APiCall";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
function DeleteAccount() {
    const navigate = useNavigate();
    const [isFocused, setIsFocused] = useState(false);
    const [isFocusedEmail, setIsFocusedEmail] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [isChecked, setIsChecked] = useState(true);
    const [isMobileConfirmed, setIsMobileConfirmed] = useState(false);
    
    const [data, setData] = useState({
        user_phone: '',
        email: '',
        });

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const handlePhoneChange = (e) => {
        // Ensure that only digits are allowed and limit to 10 characters
        const phoneNumber = e.target.value.replace(/\D/g, '').slice(0, 10);
        setData({ ...data, user_phone: phoneNumber });
      };

      const handleEmailChange = (e) => {
        const email = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    
        // Update the state with the entered email value
        setData({ ...data, email: email });
    
        // Validate email format
        if (!emailRegex.test(email)) {
          setEmailError('Invalid email format'); // Set error message
        } else {
          setEmailError(''); // Clear error if valid
        }
      };

      const submitDelete = (e) =>{
             e.preventDefault();
             const form = e.currentTarget;
             //alert('checking validation!');
             const user_phone = data?.user_phone;
             const email = data?.email;

            // Regular expression to match exactly 10 digits
            const phoneRegex = /^\d{10}$/;
            // Regular expression to match the email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

            // Check if the phone number is valid
            if (!phoneRegex.test(user_phone)) {
            toast.error('Please enter a valid 10-digit mobile number.');
            return; // Prevent the form submission if validation fails
            }

            if (!emailRegex.test(email)) {
                toast.error('Please enter a valid email address.');
                return; // Prevent form submission if email is invalid
            }

            if (form.checkValidity()) {
                if (!isMobileConfirmed) {
                    // If mobile is not confirmed yet, call "confirm_mobile" API
                    const confirmMobileData = new FormData();
                    //confirmMobileData.append('user_id', data?.user_id);
                    confirmMobileData.append('user_phone', data?.user_phone);
                    confirmMobileData.append('email', data?.email);
                    PostAPI({ url: '/v2_addAccountDeleteRequest', data: confirmMobileData }, (res) => {
                        //alert('success');
                        if (res.status == 1 || res.status == true) {
                          toast.success(res.message);
                          navigate('/');
                          alert(res.message);
                          sessionStorage.setItem('dau_id', res.id);
                        } else {
                          // Handle failure
                          if (res.status == 0 || res.status == false) {
                            toast.error(res.message);
                            alert(res.message);
                            console.log('failure:', res.message);
                            navigate('/');
                          }
                      }
                    });
                }
            }
      }

  

  return (
      <div className='section-acc'>
          <div className='card-inside'>
              <section className="no-popup">
                  <section className="no-popupInner login_signup">
                      <div className='img-grid-acc'>
                          <img src={Assets.loginPopupBg} alt="Login to Laiqa Wellness" />
                      </div>
                      <div className="popupFormInner">
                          <div className="checkoutLoginPhoneNumberHeading">
                              Delete Your LAIQA Account
                          </div>
                          <div className="checkoutLoginPhoneNumberHeading2">
                              Deleting your account means deleting all data associated with your account including all the subscriptions/purchases made through your account.
                          </div>
                          <form onSubmit={submitDelete} >
                              <div className="loginFormWrapper" style={{ position: 'relative' }}>
                                  <div className='' style={{ position: 'relative' }}>
                                      <img style={{ height: '28px', width: '28px', position: 'absolute', left: '15px', top: '17px' }} src={Assets.indianFlag} alt="India" />
                                      <p className='text' style={{ color: isFocused ? "#D27060" : "#000" }}>Mobile number*</p>
                                      <p htmlFor="loginNumberId" style={{ top: '10px' }}>+91</p>
                                      <input id="loginNumberId" type="tel"
                                          onChange={handlePhoneChange}
                                          value={data?.user_phone}
                                          onFocus={() => setIsFocused(true)}
                                          onBlur={() => setIsFocused(false)}
                                          placeholder='Enter your mobile number' autocomplete="off" />
                                  </div>
                                  <div className='' style={{ position: 'relative', marginTop: '20px' }}>
                                      <p className='text' style={{ color: isFocusedEmail ? "#D27060" : "#000" }} id="corporateFormCompany">Email</p>
                                      <input type="text" className='emailacc'
                                          id="corporateFormCompanyInput"
                                          onChange={handleEmailChange}
                                          value={data?.email}
                                          onFocus={() => setIsFocusedEmail(true)}
                                          onBlur={() => setIsFocusedEmail(false)}
                                          placeholder="Enter your email" autocomplete="off" />
                                      {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                                  </div>
                                  <div className={`rdwPopupCheck ${isChecked ? "rdwPopupChecked" : ""
                                      }`}>
                                      <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={toggleCheckbox}
                                      />
                                      <span className="checkmarkEmpty"></span>
                                      <img
                                          alt=""
                                          src={Assets.check}
                                          className="checkmarkFilled"
                                      />
                                      <p style={{ paddingLeft: '20px' }}> I authorise LAIQA to delete my account and all data associated with it</p>
                                  </div>
                                  <h5 className='text-center h5_'>You will be receiving an Email for verification</h5>
                                  <button type='submit' id="loginPhoneNumberSubmit">
                                      Submit
                                  </button>
                              </div>
                          </form>
                      </div>
                  </section>
              </section>
          </div>
          <ToastContainer />
      </div>
  )
}

export default DeleteAccount
