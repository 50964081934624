import { Assets } from "../Assets"

import { useState } from "react";

const WhyOnlyUsHomePage = () => {

    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);

    return ( 
        <div className="whyOnlyUsContent">
            <img alt="" className="whyOnlyUsImageBg1" src={ Assets.whyOnlyUsBg1 } />
             <div className="whyOnlyUsImageContent">
                <div className="whyOnlyUsImageWrapper">
                    <ul className="whyOnlyUsImagesUl">
                        <li>
                            <img alt="" src={ Assets.whyOnlyUsHoverImage1 } />
                        </li>
                        <li
                            onMouseEnter={() => setIsHovered2(true)}
                            onMouseLeave={() => setIsHovered2(false)} >
                            <img alt="" src={isHovered2 ? Assets.whyOnlyUsHoverImage2 : Assets.whyOnlyUsImage2 } />
                        </li>
                        <li
                            onMouseEnter={() => setIsHovered3(true)}
                            onMouseLeave={() => setIsHovered3(false)} >
                            <img alt="" src={isHovered3 ? Assets.whyOnlyUsHoverImage3 : Assets.whyOnlyUsImage3 } />
                        </li>
                        <li
                            onMouseEnter={() => setIsHovered4(true)}
                            onMouseLeave={() => setIsHovered4(false)} >
                            <img alt="" src={isHovered4 ? Assets.whyOnlyUsHoverImage4 : Assets.whyOnlyUsImage4 } />
                        </li>
                    </ul>
                </div>
                <div className="whyOnlyUsTextContent">
                    <p className="whyOnlyUsTextHeading">Why Only Us?</p>
                    <p className='whyOnlyUsTextHeading2'>Laiqa Provides The Best</p>
                    <p className="whyOnlyUsTextHeading3">Wellbeing Personalized Plans</p>
                    <p className="whyOnlyUsTextArticle">All bodies are different, and everyone needs something unique, we after going through all your reports according to the information provided by you ,we suggest the best well being personalized Plan that suits you the most and helps your transformation to the at most level.</p>
                    <ul className='whyOnlyUsTextList'>
                        <li>
                            Best Product for Female Wellness
                        </li>
                        <li>
                            Medical Social Services
                        </li>
                        <li>
                            Experts Consultants
                        </li>
                        <li>
                            Personalized Care Services
                        </li>
                        <li>
                            Healthy Wellbeing
                        </li>
                        <li>
                            Some more key benefits
                        </li>
                    </ul>
                </div>
             </div>
            <img alt="" className="whyOnlyUsImageBg2" src={ Assets.whyOnlyUsBg2 } />
        </div>
     );
}
 
export default WhyOnlyUsHomePage;