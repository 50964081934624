import React, { Component } from 'react';

import NavbarWellnessPlans from './WellnessPlans/NavbarWellnessPlans';
import MainContentWellnessPlansCheckoutLoginSuccess from './WellnessPlanCheckoutLoginSuccess/MainContentWellnessPlansCheckoutLoginSccess';
import SelectedPlansWellnessPlansCheckoutLoginSuccess from './WellnessPlanCheckoutLoginSuccess/SelectedPlansWellnessPlansCheckoutLoginSuccess';
import OurDoctorsTeleconsultation from './Teleconsultation/OurDoctorsTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter';
import NavbarHomePage from './Home/NavbarHomePage';

class WellnessPlansCheckout extends Component {
    render() {
        return(
            <div className="wellnessPlansIndex">
                {/* <NavbarWellnessPlans /> */}
                <NavbarHomePage />
                <MainContentWellnessPlansCheckoutLoginSuccess />
                <SelectedPlansWellnessPlansCheckoutLoginSuccess />
                <div className="ourDoctorsCheckoutWrapper">
                    <OurDoctorsTeleconsultation />
                </div>
                <div className="wellnessFooter3">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default WellnessPlansCheckout;