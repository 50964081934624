import React, { Component } from 'react';

import NavbarWellnessPlans from './WellnessPlans/NavbarWellnessPlans';
import MainContentWellnessPlans from './WellnessPlans/MainContentWellnessPlans';
import WomenWellnessPlans from './WellnessPlans/WomenWellnessPlans';
import HormonalImbalanceWellnessPlans from './WellnessPlans/HormonalImbalanceWellnessPlans';
import WellnessPlansScrollContent from './WellnessPlans/WellnessPlansScrollContent';
import MissionWithTheWellnessPlans from './WellnessPlans/MissionWithTheWellnessPlans';
import OurDoctorsTeleconsultation from './Teleconsultation/OurDoctorsTeleconsultation';
import FAQWellnessPlans from './Teleconsultation/FAQTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter'
import NavbarHomePage from './Home/NavbarHomePage';

class WellnessPlans extends Component {
    render() {
        return(
            <div className="wellnessPlansIndex">
                {/* <NavbarWellnessPlans /> */}
                <NavbarHomePage />
                <MainContentWellnessPlans />
                <WomenWellnessPlans />
                <HormonalImbalanceWellnessPlans />
                <WellnessPlansScrollContent />
                <MissionWithTheWellnessPlans />
                <div className="ourDoctorsWellnessPlansWrapper">
                    <OurDoctorsTeleconsultation />
                </div>
                <div className="FAQWellnessPlans">
                    <FAQWellnessPlans />
                </div>
                <div className="wellnessFooter1">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default WellnessPlans;