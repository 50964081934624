import React, { Component } from 'react';

import LaiqaNavbar from './Home/NavbarHomePage';
import MainContentHomePage from './Home/MainContentHomePage';
import WhyOnlyUsHomePage from './Home/WhyOnlyUsHomePage';
import ConsultationContentHomePage from './Home/ConsultationContentHomePage';
import ShopAndFeedback from './Home/ShopAndFeedback';
import BlogSectionHomePage from './Home/BlogSectionHomePage';
import FeaturedAndFooter from './Home/FeaturedAndFooter';
import NavbarHomePage from './Home/NavbarHomePage';
import Testimonials from './Home/Testimonials';

class Home extends Component {
    render() {
        return(
            <>
                <div className="homePage">
                {/* <LaiqaNavbar /> */}
                <NavbarHomePage />
                <MainContentHomePage />
                <WhyOnlyUsHomePage />
                <ConsultationContentHomePage />
                <div className="shopContentHomePage">
                <ShopAndFeedback />
                </div>
                <Testimonials />
                <BlogSectionHomePage />
                <div className="homeFooter1">
                <FeaturedAndFooter />
                </div>
                </div>
            </>
        )
    }
}

export default Home;