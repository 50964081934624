import React, { Component } from 'react';

// import NavbarStorePage from "./Store/NavbarStorePage"
// import NavMenu from './navbar/NavMenu';
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentStorePage from "./Store/MainContentStorePage"
import ShopContentStorePage from './Store/ShopContentStorePage';
import FeaturedAndFooter from './Home/FeaturedAndFooter'

class Store extends Component{
    render() {
        return (
            <div style={{position:'relative'}}>
                <NavbarKyw />
                <MainContentStorePage />
                <div className="shopContentStorePage">
                    <ShopContentStorePage />
                </div>
                <div className="featuredAndFooterStore">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default Store;