import React,{useState, useEffect} from "react";
import { Assets } from "./Assets";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw14 from "./Kyw14/ContentFrameKyw14";
import FooterKyw from "./Kyw1/FooterKyw";
import { PostAPI, GetAPI } from "./utility/APiCall";
import { useNavigate } from "react-router-dom";
// import NavMenu from "./navbar/NavMenu";

const KnowYourWellness14 = () => {
    
    const navigate = useNavigate();

    const [questionId, setQuestionId] = useState([])

    

    const get_questionnaires = (e) => {
         const categoryData = new FormData();
         //categoryData.append('category_id', getCatId);
         categoryData.append('category_id', 1);
         //categoryData.append('category', "Sleep");
 
         PostAPI({ url: '/get_onboarding_questionnaires', data: categoryData }, (res) => {
             console.log('Questions', res?.data);
             const question_id = res?.data;
             console.log('question_id',question_id);
             setQuestionId(question_id);
         });
     }

useEffect(()=>{
    get_questionnaires();
},[]);

///get_user_plan_details/7
const [getPlan, setGetPlan] = useState();
const [journeyId, setJourneyId] =useState();
const [planDataId, setplanDataId] =useState();
const [month, setMonth] = useState();
console.log('getPlangetPlan', getPlan);

const getPlandetails = () => {

    const getUID = sessionStorage.getItem('getUID')
    const userId = getUID;
    //const url = `/get_user_plan_details/${userId}`;
    const url = `/v2_get_user_plan_details/${userId}`;

    GetAPI(url, (res)=>{
                const plan_data_id = res?.data?.plan_data_id;
                if (plan_data_id == null || plan_data_id == 0){
                setplanDataId(0);
                } else {
                setplanDataId(plan_data_id);
                }

                const pre_journey_id = res?.data?.pre_journey_id;
                if (pre_journey_id == null || pre_journey_id == 0){
                setJourneyId(0);
                } else {
                setJourneyId(pre_journey_id);
                }

                const current_month = res?.data?.plan_data_id;
                if (current_month == null || current_month == 0){
                setMonth(0);
                } else {
                setMonth(current_month);
                }
            const result = res?.data;
            setGetPlan(result);
    });
}

useEffect(()=>{
    getPlandetails();
}, [])

const [inputValue, setInputValue] = useState({});
const [sleep_scale, setSleep_scale] = useState([])
const [showLoginPopup, setShowLoginPopup] = useState(false);



const [getStress, setGetStress] = useState(null);
const [getStress2, setGetStress2] = useState(null);

   // console.log('getBMIIDgetStress', getStress);

    const getCategoryList = () => {
    const url = `/get_all_onboarding_categories`;
    GetAPI(url, (res)=>{
        const getdetails = res?.data;
        console.log('getdetails', getdetails);
        // const getStress = res?.data.find(item => item.id === "1");
        // const getStress2 = res?.data.find(item => item.id === "2");
        const getStress = res?.data.find(item => item.id === 1);
        const getStress2 = res?.data.find(item => item.id === 2);
        const StressID = getStress.id;
        const StressID2 = getStress2.id;
        console.log('getStress', StressID);
        sessionStorage.setItem('getStress', StressID);
        sessionStorage.setItem('getStress2', StressID2);
        setGetStress(StressID);
        setGetStress2(StressID2);
    });
    }

    useEffect(()=>{
        getCategoryList();
    },[]);

    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img alt="" id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            <NavbarKyw />
            <MainContentKyw />
            <ContentFrameKyw14 getStress={getStress} setGetStress={setGetStress} getStress2={getStress2} setGetStress2={setGetStress2} />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness14;