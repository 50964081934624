import React, { Component } from 'react';

import NavbarAboutUs from './Home/NavbarHomePage';
import MainContentAboutUs from './AboutUs/MainContentAboutUs';
import AboutUsIntro from './AboutUs/AboutUsIntro';
import KeyFeaturesAboutUs from './AboutUs/KeyFeaturesAboutUs';
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import AboutUsScrollContent from './AboutUs/AboutUsScrollContent';
import NavbarHomePage from './Home/NavbarHomePage';

class AboutUs extends Component {
    render() {
        return(
            <div className="aboutUs">
                <NavbarHomePage />
                {/* <NavbarAboutUs /> */}
                <MainContentAboutUs />
                <AboutUsIntro />
                <AboutUsScrollContent />
                <KeyFeaturesAboutUs />
                <div className="aboutUsFooter">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default AboutUs;