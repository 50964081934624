import { Assets } from "../Assets"

const MainContentDietPlans = () => {
    return ( 
        <div className="laiqaMainContentBg">
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img src={ Assets.directPlansMainContentBg } id="landingPageBannerImg1" />
                    <img src={ Assets.laiqaMainContentBgImgResp } id="landingPageBannerImg2" />
                </div>
            </div>
        </div>
     );
}
 
export default MainContentDietPlans;