import React from 'react';
import './CustomPagination.css';

const CustomPaginationNew = (props) => {
  const { pagination, setPagination, onPageChange } =
    props.CustomPaginationprops;
  const { activePage, itemsPerPage, totalListCount } = pagination;

  const handleFirstPage = () => {
    if (pagination.activePage !== 1) {
      onPageChange(1);
    }
  };

  const handleLastPage = () => {
    const totalPages = Math.ceil(
      pagination.totalListCount / pagination.itemsPerPage
    );
    if (pagination.activePage !== totalPages) {
      onPageChange(totalPages);
    }
  };

  const buttonStyle = {
    backgroundColor: '#fff',
    color: '#222631',
    borderRadius: '10px',
    height: '35px',
    width: '40px',
    border: '2px solid #DEDDF5',
  };

  const buttonStyle_activepage = {
    backgroundColor: '#fff',
    color: '#222631',
    borderRadius: '10px',
    height: '35px',
    width: '45px',
    border: '2px solid #DEDDF5',
  };

  const activeButtonStyle = {
    backgroundColor: '#222631',
    color: '#fff',
    borderRadius: '10px',
    height: '35px',
    width: '40px',
    border: '2px solid #DEDDF5',
  };

  const activeButtonStyle_prev_next = {
    backgroundColor: '#222631',
    color: '#fff',
    borderRadius: '10px',
    height: '35px',
    width: '45px',
    border: '2px solid #DEDDF5',
  };

  const totalPages = Math.ceil(totalListCount / itemsPerPage);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const text_color = {
    color: '#222631',
  };

  const itemsPerPage_style = {
    border: 'Mixed solid #DEDDF5',
    borderRadius: '5px',
    height: '25px',
    width: '48px',
  };

  const item_shown = ['5', '10', '25', '50'];

  const handlePrevPage = () => {
    if (pagination.activePage > 1) {
      onPageChange(pagination.activePage - 1);
    }
  };

  const handleNextPage = () => {
    if (pagination.activePage < totalPages) {
      onPageChange(pagination.activePage + 1);
    }
  };

  const maxPageNumberLimit = 4; // Set the maximum number of page buttons to display on each side of the active page.

  return (
    <div
      className='custom-pagination-box new d-flex align-items-center justify-content-between'
      style={{ marginBottom: '20px' }}
    >
      <div className='Pagination_section'>
        <ul className='pagination'>
          <li
            className='pagination-button page-item me-3'
            disabled={pagination.activePage === 1}>
            <a onClick={handleFirstPage} style={{cursor:'pointer'}} className='page-link'> {'<'} </a>
          </li>
          <li
            className='pagination-button page-item me-3'
            disabled={pagination.activePage === 1}>
            <a onClick={handlePrevPage} style={{cursor:'pointer'}} className='page-link'> {'<'} </a>
          </li>
        {/* {pageNumbers.map((pageNumber) => (
        <button
        key={pageNumber}
        className={`pagination-button me-3 ${
        pageNumber === activePage ? 'active' : ''
        }`}
        style={pageNumber === activePage ? activeButtonStyle : buttonStyle}
        onClick={() => onPageChange(pageNumber)}
        >
        {pageNumber}
        </button>
        )} */}
          {pageNumbers.map((pageNumber) => {
            if (
              pageNumber >= activePage - maxPageNumberLimit &&
              pageNumber <= activePage + maxPageNumberLimit &&
              pageNumber <= totalPages &&
              pageNumber > 0
            ) {
              return (
                <li
                  key={pageNumber}
                  className={`pagination-button page-item me-3 ${
                    pageNumber === activePage ? 'active' : ''
                  }`}
                >
                  <a
                    onClick={() => onPageChange(pageNumber)}
                    className='page-link'
                  >
                    {pageNumber}
                  </a>
                </li>
              );
            }
            return null; // Return null for page numbers outside the limit
          })}
          <li
            className='pagination-button me-3 page-item'
            disabled={pagination.activePage === totalPages}>
            <a onClick={handleNextPage} style={{cursor:'pointer'}} className='page-link'>
              {'>'}
            </a>
          </li>

          <li
            className='pagination-button me-3 page-item'
            disabled={pagination.activePage === totalPages}
          >
            <a onClick={handleLastPage} style={{cursor:'pointer'}} className='page-link'>
              {'>'}
            </a>
          </li>
        </ul>
      </div>
        {/* <div className='itemperpage_custom'>
        <span className='me-2'>Show</span>
        <select
        style={itemsPerPage_style}
        value={pagination.itemsPerPage}
        onChange={(e) =>
        setPagination({ ...pagination, itemsPerPage: e.target.value })
        }
        >
        {item_shown?.map((o, i) => (
        <option key={i} value={o}>
        {o}
        </option>
        ))}
        </select>
        <span className='ms-2'>entries</span>
        </div> */}
    </div>
  );
};

export default CustomPaginationNew;
