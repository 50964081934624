import { Assets } from '../Assets';

import PopupRegisterForWorkshop from './PopupRegisterForWorkshop'
import PopupRegisterSuccessful from './PopupRegisterForWorkshopSuccessful'

import { useEffect, useState } from 'react';
import {PostAPI, GetAPI, PatchAPI, DeleteAPI} from '../utility/APiCall' 
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RegisterForWorkshop = ({ onActiveChange }) => {

    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        autoplay: false,
        fade:true,
        autoplaySpeed: 6000,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };

    const [isHovered, setIsHovered] = useState(false);
    const [showWorkshopPopup, setShowWorkshopPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [activeWorkshop, setActiveWorkshop] = useState(null);

    const openWorkshopPopup = () => {
        setShowWorkshopPopup(true);
    };
    
    const closeWorkshopPopup = () => {
        setShowWorkshopPopup(false);
    };
    
    const openSuccessPopup = () => {
        setShowSuccessPopup(true);
        closeWorkshopPopup();
    };
    
    const closeSuccessPopup = () => {
        setShowSuccessPopup(false);
    };

    const [workshop, setWorkshop] = useState([])

    const workshopInfo = () => {
        const url = `/web/workshops`;
        GetAPI(url, (res) => {
            if (res.status === 1) {
                const workshops = res.data;
                setWorkshop(workshops);
                const activeWorkshopData = workshops.find((workshop) => workshop.workshop_status === 'Active');
                setActiveWorkshop(activeWorkshopData);
                onActiveChange(true);
                console.log('Active Workshop:', activeWorkshopData);
              } else {
                console.error('Error fetching workshop details:', res.message);
              }
        });
    }

    useEffect(() => {
        workshopInfo();
    }, []);



    return(
        <div className="registerForWorkshopContainer">
            { activeWorkshop ? (
                <div>
                    <PopupRegisterForWorkshop 
                        show={showWorkshopPopup} 
                        handleClose={closeWorkshopPopup} 
                        openSuccessPopup={openSuccessPopup} 
                        workshopId={ activeWorkshop.id }
                        workshopDate={ activeWorkshop.workshop_date }
                        workshopTime={ activeWorkshop.workshop_time }
                    />
                    <PopupRegisterSuccessful 
                        show={showSuccessPopup}
                        handleClose={closeSuccessPopup}
                    />

                    <div className="registerForWorkshopWrapper">
                    <div className="registerForWorkshopBg">
                        <img src={ Assets.registerWorkshopBannerImg } alt="Register for Workshop" />
                    </div>
                    <div className="registerForWorkshopContent">
                        <h1 className="rfwHeding1">Upcoming</h1>
                        <h2 className="rfwHeding2"><b>Workshops</b> helping you know better</h2>
                       
                            <Slider {...settings}>
                            {workshop.map((item, i) => (
                            <div key={i}>
                            <div className="registerForWorkshopInfo">
                            <div className="registerForWorkshopInfoLeft">
                                <div className="rfwDocInfo">
                                    <div className="rfwDoctorImage">
                                        <img src={ Assets.rfwDoctorImage } alt="Doctor" />
                                    </div>
                                    <div className="rfwDoctorTextContent">
                                        <p>{ item.conducted_by }</p>
                                        <div className="rfwDoctorMoreInfo">
                                            <p>Workshop Mode</p>
                                            <p>•</p>
                                            <p style={{ textTransform:"uppercase" }}>{ item.mode }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="rfwWorkshopInfo">
                                    <div className="rfwWorkshopHeading">
                                        <h1>{ item.name }</h1>
                                    </div>
                                    <div className="rfwWorkshopArticle">
                                        <p>{ item.description }</p>
                                    </div>
                                    <div className="rfwWorkshopEndContent">
                                        <div className="rfwWorkshopEndContentDetails">
                                            <div className="rfwWorkshopEndContentDate">
                                                <img alt='' src={ Assets.calender } />
                                                <p>{ item.workshop_date }</p>
                                            </div>
                                            <div className="rfwWorkshopEndContentTiming">
                                                <img alt='' src={ Assets.clock } />
                                                <p>{ item.workshop_time }</p>
                                            </div>
                                        </div>
                                        <div className="rfwWorkshopEndContentBookNowButton">
                                            <button id="rfwBookNowButton"
                                                className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                                                onMouseEnter={() => setIsHovered(true)}
                                                onMouseLeave={() => setIsHovered(false)}
                                                onClick={ openWorkshopPopup }
                                                >
                                                Book Now
                                                {isHovered ? <img alt='' src={ Assets.arrowUpRight } /> : <img alt='' src={ Assets.arrowUpRightBlack } /> }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="registerForWorkshopInfoRight">
                                <img alt='' src={ Assets.banner } />
                            </div>
	 </div>
     </div>
))}
</Slider>
                            

                        
                       </div>
                    </div>
                </div>
            ) : (
                <p></p>
            )}
        </div>
    )
}

export default RegisterForWorkshop;