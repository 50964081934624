import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Custom hook for managing cookies
function useCookie(key, initialValue) {
  const [value, setValue] = useState(() => {
    const cookie = Cookies.get(key);
    if (cookie) return cookie;
    Cookies.set(key, initialValue);
    return initialValue;
  });

  useEffect(() => {
    Cookies.set(key, value);
  }, [key, value]);

  return [value, setValue];
}

export default useCookie;