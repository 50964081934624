import { Assets } from '../Assets';

const AboutUsScrollContent = () => {
    
    return(
        <div className="aboutUsScrollContent">
            <div className="laiqaMainContentWrapper">
                <div className="scrollImageWrapper">
                    <img src={ Assets.aboutUsScroll2 } />
                    <img src={ Assets.aboutUsScroll3 } />
                    <img src={ Assets.aboutUsScroll4 } />
               </div>
            </div>
        </div>
    );
}

export default AboutUsScrollContent;