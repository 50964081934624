// import React from 'react'
// import { createContext, useState } from "react";

// const AppContext = createContext({
//   allAtOnce: false,
//   loading: true,
//   setLoading: () => {}
// });

// export const AppContextProvider = ({ children }) => {
//   const [loading, setLoading] = useState(true);

//   return (
//     <AppContext.Provider value={{ loading, setLoading }}>
//       {children}
//     </AppContext.Provider>
//   );
// };

// export default AppContext;

import React, { createContext, useContext, useState } from 'react';
import useCookie from '../cookies/Cookie';
import Cookies from 'js-cookie';
import { PostAPI } from '../utility/APiCall';
import { useNavigate } from 'react-router-dom'; 
import { toast } from 'react-toastify';

const AppContext = createContext({
  allAtOnce: false,
  loading: true,
  setLoading: () => {},
  userName: '',
  setUserName: () => {},
  userId: '',
  setUserId: () => {},
  sharedData: null,
  setSharedData: () => {},
  handleLogOut: () => {},
  isJourneyFilled: false,
});

export const AppContextProvider = ({ children, handleLogOut }) => {

  const [loading, setLoading] = useState(true);

  const [userName, setUserName] = useCookie('user_name', '');
  const [userId, setUserId] = useCookie('getUID', '');
  const getJID = sessionStorage.getItem('journey_id');

  const [sharedData, setSharedData] = useState(null);

  const setSharedDataValue = (data) => {
    setSharedData(data);
  };
// Function to check if the journey is already filled for the month
const checkJourneyFilled = () => {
  const currentMonth = new Date().getMonth();
  const storedMonth = sessionStorage.getItem('journey_month');
  return storedMonth && parseInt(storedMonth) === currentMonth;
};

  return (
    <AppContext.Provider  
      value={{ 
      loading, 
      setLoading, 
      sharedData, 
      setSharedData: setSharedDataValue, 
      userName,
      setUserName,
      userId,
      setUserId, 
      handleLogOut,
      getJID,
      isJourneyFilled: checkJourneyFilled(),
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useData = () => {
  return useContext(AppContext);
};

export const useName = () => {
  return useContext(AppContext);
};

export { AppContext };
export default AppContextProvider;
