import React, {useEffect} from "react";
import { Assets } from "../Assets"
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { GetAPI } from "../utility/APiCall";

const ContentFrameKyw9 = (props) => {
const {setQuestionId, questionId, inputValue, handleInputChange, 
    sliderValue, handleSliderChange, inputValue2, handleInputChange2, 
    sliderValue2, inputValue3, handleInputChange3,sliderValue3, handleSliderChange3, 
    handleSliderChange2, handleSliderChange4, sliderValue4, handleInputChange4, inputValue4, data, setData,
    getSleepData, setSleepData, message, setMessage, getSleepscaleData, setSleepscaleData 
}= props;
const navigate = useNavigate();
    
const [isHovered, setIsHovered] = useState(false);
const [formSubmitted, setFormSubmitted] = useState(false);

const handleSubmit = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        setFormSubmitted(true); // Update the state to mark form as submitted
        props.handleFormSubmit(e); // Call the parent's form submission function
        //navigate('/selfprognosis/know-your-wellness/activity-assessment-result');
        console.log('datacheck',data);
        
};

const getCategoryList = () => {
    const url = `/get_all_onboarding_categories`;
    GetAPI(url, (res)=>{
        const getdetails = res?.data;
        console.log('getdetails', getdetails);
        //const getBMIID = res?.data.find(item => item.id === "7");
        const getBMIID = res?.data.find(item => item.id === 7);
        const bmiID = getBMIID.id;
        console.log('getMenstrualPain', bmiID);
        sessionStorage.setItem('getMenstrualPain', bmiID);
       // setGetBMIID(bmiID);

    });
    }

    useEffect(()=>{
        getCategoryList();
    },[]);

    const clickNext = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        navigate('/selfprognosis/know-your-wellness/pain-assessment');
        //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
    }

    const [visible, setVisible] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const openLoginPopup = () => {
        //setShowLoginPopup(true);
        setFormSubmitted(true); // Update the state to mark form as submitted
        //alert('Check');
    };
    
    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    //const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';
    const showHideClassName2 = showLoginPopup
    ? 'loginPopupBg result display-block'
    : 'loginPopupBg result display-none';

    const user_name = sessionStorage.getItem("user_name");

    const getBmiCategoryColor = () => {
        if (getSleepData < 600) {
            return '#7AB375';
        } else if (getSleepData >= 600 && getSleepData < 3000) {
            return '#E9DD6C';
        } else {
            return '#D27060';
        }
    };

    const activity_selectedScaleItem = getBmiCategoryColor();

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
            <div className="kyw1BgImage position-relative">
            <div className='absoluteStage'>
                <p className="mb-0" style={{marginBottom:'0px'}}>Step 5</p>
                <p>Physical Activity Assessment</p>
                </div>
                    <img src={ Assets.kyw8bg } alt="Physical Activity Assessment" />
                    <div className='absolute'>
                    <h4>How active are you?</h4>
                    <p>
                    Do you know an active lifestyle does not stop just at energizing but helps you have a smoother period and less cramping? 
                    </p>
                    <p>
                    Answer these questions and we so will understand your lifestyle
                    </p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                <div className="vertProgressLine5"></div>
                <div className="progressCirclesContainer">
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg5" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleHalf" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw1MainContent">
                {formSubmitted ? (
                        <>
					
                        <div className="kyw4MainContentHeading">
                        <p>Hey {user_name},</p>
                    </div>
                    <div className="kyw4MainContentSubHeading">
                        <p>Thanks for taking Physical Activity Assessment test.</p>
                    </div>
                    <div className="bmiResultWrapper">
                        <div className="bmiResultHeading">
                            <p>Based on your answers, your score is</p>
                        </div>
                        <div className="bmiResult">
                            <div className="activityResultFloatResponse">
                                <input
                                    type="text"
                                    id="bmiHeightInputId"
                                    value={ getSleepData }
                                    maxLength={ 4 }
                                />
                            </div>
                            <div className="bmiResultStringResponse">
                                <div style={{ marginTop: '10px', color: activity_selectedScaleItem }}>
                                {activity_selectedScaleItem && activity_selectedScaleItem.id == 27
                                ? "Low"
                                : activity_selectedScaleItem && activity_selectedScaleItem.id == 28
                                ? "Moderate" 
                                : activity_selectedScaleItem && activity_selectedScaleItem.id == 29
                                ? "High"
                                : activity_selectedScaleItem && activity_selectedScaleItem.id == 0
                                ? ""
                                : ""}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="paaSliderContainer">
                                <input
                                    type="range"
                                    id="slider"
                                    className="bmiSlider"
                                    name="slider"
                                    min="0"
                                    max="4000"
                                    value={ getSleepData }
                                    // onChange={ handleSliderChange }
                                />
                            </div>
                        </div>
                        <div className="bmiLogoBr">
                            <img alt="" src={ Assets.paaLogo } />
                        </div>
                    </div>
                    <div className="bmiCategories">
                        <ul>
                         {getSleepscaleData && getSleepscaleData.map(item => (
                        <li className={item.selected ? 'selected' : ''} key={item.id}>
                        {item.description}
                        </li>
                        ))}
                        </ul>
                    </div>
                    <div className="kywNextLinkWrapper">
                        <Link className="backLink" to="">
                            <img src={ Assets.backArrow } alt="Back" /> Back
                        </Link>
                        <button type="button" onClick={clickNext} id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}> Next
                                { isHovered ? <img alt="" src={ Assets.arrowUpRight } /> : <img alt="" src={ Assets.arrowUpRightBlack } /> }
                        </button>
                       </div>
					</>
                  ) : (
                    <>
                    <div className="kyw8MainContentHeading">
                     <h5 style={{lineHeight:'1.5'}}>
                     For each of the activity chosen, select how many hours and minutes you spend on it per day?
                     </h5>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className="activityInputWrapper">
                        <div className="kywSliderUpperContent">
                            <div className="kywActivityQuestionHeading">
                                <p><b>Active</b> (running or heavy lifting)</p>
                            </div>
                            <div className="activityInput2">
                                <input
                                    value={inputValue?.hours ? inputValue.hours : inputValue.minutes}
                                    onChange={ handleInputChange }
                                    id="activityActiveInputId"
                                    type="text"
                                    maxLength={ 2 } hidden />
                                <label htmlFor="activityActiveInputId">Hours/Minutes {inputValue?.hours} :  {inputValue?.minutes}</label>
                            </div>
                        </div>
                        <div className="kyw3MainContentItems">
                            <div className="activitySlideContainer">
                                <input
                                    type="range"
                                    id="sliderHeight"
                                    className="bmiSlider"
                                    name="slider"
                                    min="0"
                                    max="8"
                                    step="0.30"
                                    value={ sliderValue }
                                    onChange={ handleSliderChange } />
                                <div className="activitySliderLabelContent">
                                    <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                    <label htmlFor="sliderHeight" >8</label>
                                </div>                            
                            </div>
                        </div>
                        <div className="kywSliderUpperContent">
                            <div className="kywActivityQuestionHeading">
                                <p><b>Moderately</b> (light jogging or bicycling)</p>
                            </div>
                            <div className="activityInput2">
                                <input
                                    value={inputValue2?.hours ? inputValue2.hours : inputValue2.minutes}
                                    onChange={ handleInputChange2 }
                                    id="activityModerateInputId"
                                    type="text"
                                    maxLength={ 3 } hidden />
                                <label htmlFor="activityModerateInputId">Hours/Minutes {inputValue2?.hours} :  {inputValue2?.minutes}</label>
                            </div>
                        </div>
                        <div className="kyw3MainContentItems">
                            <div className="activitySlideContainer">
                                <input
                                    type="range"
                                    id="sliderHeight"
                                    className="bmiSlider"
                                    name="slider"
                                    min="0"
                                    max="8"
                                    step="0.30"
                                    value={ sliderValue2 }
                                    onChange={ handleSliderChange2 }
                                />
                                <div className="activitySliderLabelContent">
                                    <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                    <label htmlFor="sliderHeight" >8</label>
                                </div>                            
                            </div>
                        </div>
                        <div className="kywSliderUpperContent">
                            <div className="kywActivityQuestionHeading">
                                <p><b>Low</b> (walking for instance)</p>
                            </div>
                            <div className="activityInput2">
                                <input
                                    value={ inputValue3?.hours ? inputValue3.hours : inputValue3.minutes }
                                    onChange={ handleInputChange3 }
                                    id="activityLowInputId"
                                    type="text"
                                    maxLength={ 3 } hidden  />
                                <label htmlFor="activityLowInputId">Hours/Minutes {inputValue3?.hours} :  {inputValue3?.minutes}</label>
                            </div>
                        </div>
                        <div className="kyw3MainContentItems">
                            <div className="activitySlideContainer">
                                <input
                                    type="range"
                                    id="sliderHeight"
                                    className="bmiSlider"
                                    name="slider"
                                    min="0"
                                    max="8"
                                    step="0.30"
                                    value={ sliderValue3 }
                                    onChange={ handleSliderChange3 }
                                />
                                <div className="activitySliderLabelContent">
                                    <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                    <label htmlFor="sliderHeight" >8</label>
                                </div>                            
                            </div>
                        </div>
                        <div className="kywSliderUpperContent">
                            <div className="kywActivityQuestionHeading">
                                <p><b>Sedentary</b> (desk work, watching TV, etc.)</p>
                            </div>
                            <div className="activityInput2">
                                <input
                                    value={ inputValue4?.hours ? inputValue4.hours : inputValue4.minutes }
                                    onChange={ handleInputChange4 }
                                    id="activitySedentaryInputId"
                                    type="text"
                                    maxLength={ 3 } hidden  />
                                <label htmlFor="activitySedentaryInputId">Hours/Minutes {inputValue4?.hours} :  {inputValue4?.minutes}</label>
                            </div>
                        </div>
                        <div className="kyw3MainContentItems">
                            <div className="activitySlideContainer">
                                <input
                                    type="range"
                                    id="sliderHeight"
                                    className="bmiSlider"
                                    name="slider"
                                    min="0"
                                    max="8"
                                    step="0.30"
                                    value={ sliderValue4 }
                                    onChange={ handleSliderChange4 }
                                />
                                <div className="activitySliderLabelContent">
                                    <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                    <label htmlFor="sliderHeight" >8</label>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className="kywNextLinkWrapper">
                            <Link className="backLink" to="" onClick={() => { navigate(-1); }}>
                            <img src={ Assets.backArrow } alt="Back" />
                            Back
                            </Link>
                            <button type="submit" id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)} 
                               // onClick={openLoginPopup}
                                 >
                                Next
                                {isHovered ? <img alt="" src={ Assets.arrowUpRight } /> : <img alt="" src={ Assets.arrowUpRightBlack } /> }
                            </button>
                      </div>
                    </form>
                    
					</>
                 )} 
                </div>
            </div>
        </div>
    )
}

export default ContentFrameKyw9;