import { Assets } from '../Assets';
import { useState } from 'react';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WomenWellnessPlans = () => {

    const [isHovered, setIsHovered] = useState(false);

    
    const settings = {
        centerMode: false,
        centerPadding: '10px',
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 5000,
       // speed: 500,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };

    const imageItems = [
        { id: 1, src: Assets.menstrualCramps },
        { id: 2, src: Assets.sleepIssues },
        { id: 3, src: Assets.weightGain },
        { id: 4, src: Assets.thyroid },
        { id: 5, src: Assets.vaginalInfection },
    ];

    const ImageItem = ({ item }) => {
        const [isHovered, setHovered] = useState(false);
        return (
                <>
                {/* <Slider {...settings}> */}
                <div className="womensPlansProblemListItem" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                <img src={item.src} alt={item.alt} />
                </div>
                {/* </Slider> */}
                </>
        );
    }

    return(
        <>
            <div className="womensWellnessPlansContent">
            <div className="whyOnlyUsImageContent">
                <div className="womenWellnessImageWrapper">
                    <img alt='' src={ Assets.womensPlansBg } />
                </div>
                <div className="womenWellnessTextContent">
                    <p className="whyOnlyUsTextHeading">Women Wellness</p>
                    <p className='womenWellnessTextHeading2'><b>Have you ever been in a situation where you can't quite put your finger on what's happening with your body? Worry not! You are not alone.</b></p>
                    <p className="womenWellnessTextArticle">
                    Are you experiencing weight gain despite following a strict diet? 
                    Or maybe you're diligently taking care of your skin, yet still dealing with pesky pimples.
                    Perhaps your gut health isn't at its best, or you're going through painful cramps during your menstrual cycle. 
                    And let's not forget about the worries regarding the quality of your hair.
                    </p>
                    <p className="womenWellnessTextArticle">
                    When was the last time you slept peacefully like there was no worry in the world?
                    </p>
                    <p className="womenWellnessTextArticle">
                    Remember, you're not alone, and there are solutions out there to help you address these concerns.
                    </p>
                </div>
            </div>
        </div>
        <div className='womensWellnessBottomBannerContent_parent'>
        <div className="womensWellnessBottomBannerContent">
                <div className="consultationContentBgImg" style={{
                    backgroundImage: `url(${Assets.womensPlansBgTopLayer})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    }}>
                    {/* <img src={ Assets.womensPlansBgTopLayer } id="consultationContentBgImg1" />
                    <img src={ Assets.womensProblemsBgResp } id="consultationContentBgImg2" /> */}
                    <div className="consultationTextContent">
                    <div className="womensWellnessContentHeading">
                        <p className="womensWellnessBottomHeading">If you've answered 'yes' to any of the questions, it's important to address the chemical messengers in your body - Hormones.</p>
                        <p className="womensWellnessBottomArticle">
                        Surprisingly, 9 out of 10 women over 22 years old face hormonal issues. Ignoring hormonal imbalances can result in complications such as Polycystic Ovary Syndrome (PCOS), Thyroid disorders, acne, infections, weight gain, sleep disturbances, and anxiety
                        </p>
                    </div>
                </div>
                <div className="womensProblemsContent womensProblemsContent_abs_no">
                    <ul className="womensPlansProblemListWrapper">
                        <Slider {...settings}>
                        {imageItems?.map((item, i) => (
                         <ImageItem key={item.id} item={item} />
                        ))}
                        </Slider>
                    </ul>
                 </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default WomenWellnessPlans;