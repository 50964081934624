import React, {useState, useEffect} from 'react';
import { Assets } from '../Assets';
import { Link } from 'react-router-dom';
import { GetAPI, PostAPI } from '../utility/APiCall';
import { useNavigate } from 'react-router-dom';
import useCookie from "../cookies/Cookie";
import Cookies from 'js-cookie';

function ContentFrameKyw2(props) {
    const {category, setCategory, clickNext} = props;
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const handleFormSubmit = (e) => {
       // e.preventdefault();
        console.log('Submitting form with category_id:', category?.category_id);
        const categoryData = new FormData();
        //const getUID = sessionStorage.getItem('getBMIID');
        const getUID = Cookies.get('getUID');
        categoryData.append('category_id', getUID);
    }

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                <div className='absoluteStage'>
                <p className="mb-0" style={{marginBottom:'0px'}}>Step 1</p>
                <p>KYW Intro</p>
                </div>
                    <img src={ Assets.kyw2BgImage } alt="Start your Journey" />
                    <div className='absolute'>
                       <h4>What is Lifestyle Quotient?</h4>
                        <p>Lifestyle Quotient" (LQ) is like a score that shows how healthy and balanced your daily habits and choices are. It considers things like what you eat, how active you are, and how you manage stress. By understanding your lifestyle quotient, you can make changes to live a healthier and more satisfying life.</p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                  <div className="progressCirclesContainer">
                     <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 1 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleFiveSixth" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter2">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 2 </div>
                        </div>
                    </div>
                     <div className="progressCirclesOuter3">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 3 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter4">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 4 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter5">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter6">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter7">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw1MainContent">
                    <div className="kyw1MainContentHeading">
                        <h1>Your Lifestyle Quotient helps you to know you better through the following</h1>
                    </div>
                    <img src={ Assets.kyw2ListImages } alt="Reasons to choose LAIQA" />
                    <form 
                        onSubmit={handleFormSubmit}
                        name={category?.id ? "update" : "insert"}>
                        <button
                        id="kywNextButton"
                        type="button"
                        className={`${isHovered ? 'bookNowTeleconsButtonHovered' : ''}`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={clickNext}> Next </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContentFrameKyw2;