import NavbarHomePage from "./Home/NavbarHomePage";
import MainContentSelfPrognosis from "./SelfPrognosis/MainContentSelfPrognosis"
import SelfPrognosisIntro from "./SelfPrognosis/SelfPrognosisIntro";
import ScheduleCallSelfPrognosis from "./SelfPrognosis/ScheduleCallSelfPrognosis";
import WhyChooseUsSelfPrognosis from "./DirectPlans/WhyChooseUsDirectPlans"
import OurDoctorsSelfPrognosis from "./Teleconsultation/OurDoctorsTeleconsultation";
import FAQSelfPrognosis from "./Teleconsultation/FAQTeleconsultation";
import FeaturedAndFooter from "./Home/FeaturedAndFooter";
import CardInfoSelfPro from "./SelfPrognosis/CardInfoSelfPro";

const SelfPrognosis = () => {
    return (
        <div className="selfPrognosis">
            <NavbarHomePage />
            <MainContentSelfPrognosis />
            {/* <div className="selfPrognosisgridbx">
                <CardInfoSelfPro />
            </div> */}
            <div className="selfPrognosisIntro">
                <SelfPrognosisIntro />
            </div>
            <ScheduleCallSelfPrognosis />
            <div className="whyChooseUsSelfPrognosis">
                <WhyChooseUsSelfPrognosis />
            </div>
            <div className="ourDoctorsSelfPrognosis">
                <OurDoctorsSelfPrognosis />
            </div>
            <div className="FAQSelfPrognosis">
                <FAQSelfPrognosis />
            </div>
            <div className="FeaturedAndFooterSelfPrognosis">
                <FeaturedAndFooter />
            </div>
        </div>
    )
}

export default SelfPrognosis;