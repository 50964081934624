import React, { Component } from 'react';

import NavbarWellnessPlans from './WellnessPlans/NavbarWellnessPlans';
import MainContentWellnessPlansCheckout from './WellnessPlansCheckout/MainContentWellnessPlansCheckout';
import SelectedPlansWEllnessPlansCheckout from './WellnessPlansCheckout/SelectedPlanWellnessPlansCheckout';
import OurDoctorsTeleconsultation from './Teleconsultation/OurDoctorsTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter';
import NavbarHomePage from './Home/NavbarHomePage';
class WellnessPlansCheckout extends Component {
    render() {
        return(
            <div className="wellnessPlansIndex">
                {/* <NavbarWellnessPlans /> */}
                <NavbarHomePage />
                <MainContentWellnessPlansCheckout />
                <SelectedPlansWEllnessPlansCheckout />
                <div className="ourDoctorsCheckoutWrapper">
                    <OurDoctorsTeleconsultation />
                </div>
                <div className="wellnessFooter3">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default WellnessPlansCheckout;