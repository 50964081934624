import React, { Component } from 'react';

import NavbarTeleconsultationPage from './Teleconsultation/NavbarTeleconsultationPage';
import MainContentTeleconsultationPage from './Teleconsultation/MainContentTeleconsultationPage';
import AreaOfConcernTeleconsultation from './Teleconsultation/AreaOfConcernTeleconsultation';
import StepsTeleconsultationPage from './Teleconsultation/StepsTeleconsultationPage';
import ConsultationContentTeleconsultationPage from './Teleconsultation/ConsultationContentTeleconsultationPage';
import WcuTeleconsultationPage from './Teleconsultation/WcuTeleconsultationPage';
import OurDoctorsTeleconsultation from './Teleconsultation/OurDoctorsTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter';
import FAQTeleconsultation from './Teleconsultation/FAQTeleconsultation';
import NavbarHomePage from './Home/NavbarHomePage';

class Teleconsultation extends Component{
    render() {
        return (
            <div className="teleconsultationPage">
                {/* <NavbarTeleconsultationPage /> */}
                <NavbarHomePage />
                <MainContentTeleconsultationPage />
                <AreaOfConcernTeleconsultation />
                <div className="stepsTelecons">
                    <StepsTeleconsultationPage />
                </div>
                <div className="consContentTelecons">
                    <ConsultationContentTeleconsultationPage />
                </div>
                <div className="whyChooseUsTeleconsultationWrapper">
                    <WcuTeleconsultationPage />
                </div>
                <div className="ourDoctorsTelecons">
                    <OurDoctorsTeleconsultation />
                </div>
                <div className="FAQTeleconsultation">
                    <FAQTeleconsultation />
                </div>
                <div className="teleconsFooter1">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default Teleconsultation;