import React, { useEffect, useState } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookie_consent');
    if (!consent) {
      setShowBanner(true);
    } else if (consent === 'accepted') {
      loadGoogleAnalytics();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie_consent', 'accepted');
    setShowBanner(false);
    loadGoogleAnalytics();
  };

  const handleReject = () => {
    localStorage.setItem('cookie_consent', 'rejected');
    setShowBanner(false);
  };

  const loadGoogleAnalytics = () => {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=UA-134710913-1";
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'AW-741266684', {'allow_enhanced_conversions':true});
      gtag('config', 'UA-134710913-1', {"anonymize_ip":"true","link_attribution":"true"});
      gtag('config', 'G-KDWH7C807M');
    `;
    document.head.appendChild(script2);
  };

  return (
    showBanner && (
      <div id="cookie-consent-banner" style={{ display: 'block', transition: 'opacity 0.5s ease-in', position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'rgb(181 138 101 / 89%)', color: '#fff', padding: '.5rem 1rem', textAlign: 'left', zIndex: 99999999999 }}>
        <div className="grid_parent">
          <div className="grid_child grid_80">
            <p>At Laiqa, we use cookies and similar technologies to enhance your experience. This helps us understand your preferences and provide personalized content. We may also use precise location data and device scanning for improved services.</p>
            <p>Your consent applies to all our subdomains. We respect your choices and prioritize your privacy. <a style={{ color: '#0a12ff', textDecoration: 'underline !important' }} href="https://mylaiqa.com/cookies-policy/">Cookie Policy</a></p>
            
          </div>
          <div className="grid_child grid_20" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <button id="accept-cookies" style={{ width:'50%', marginBottom: '10px', borderRadius: '25px', padding: '10px 20px', fontSize: '14px !important', lineHeight: '23px !important', background: '#fff !important', color: '#000 !important', border: '1px solid #707070 !important', fontWeight: 400 }} onClick={handleAccept}>Accept All</button>
            <button id="reject-cookies" style={{ width:'50%',marginBottom: '0px', borderRadius: '25px', padding: '10px 20px', fontSize: '14px !important', lineHeight: '23px !important', background: '#fff !important', color: '#000 !important', border: '1px solid #707070 !important', fontWeight: 400 }} onClick={handleReject}>Reject All</button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieConsent;