import React,{useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { BrowserRouter, Router, Switch, Route, Routes, useLocation } from 'react-router-dom';
import AppContent from './AppContent';
import {AppContextProvider, AppContext } from './Components/Context/AppContextProvider';
import NavbarHomePage from './Components/Home/NavbarHomePage';
import AppContextProviderWithRouter from './Components/Context/AppContextProviderWithRouter';
function App() {
  
  return (
    <>
  <Router>
      <AppContextProviderWithRouter>
      <AppContextProvider />
        <AppContent />
        {/* <NavbarHomePage /> */}
      </AppContextProviderWithRouter>
    </Router>
    </>

  );
}

export default App;