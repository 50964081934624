import React from 'react'
import { Assets } from '../Assets'
function SafeSecure() {
  return (
    <div className='' style={{
        backgroundImage: `url(${Assets?.safe_secure})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div className='section-container'>
      <div className='content grid_5'>
        <p>Safe and Secure</p>
        <h2>We are compliant to <span>Industry standards. </span></h2>
       </div>
        <div className='content_img grid_5'>
        <div className='boximg'>
        <div className='grid_bx_bgm'>
        <img alt='' src={Assets.bit_256} />
        </div>
        <div className='grid_bx_bgm'>
        <img alt='' src={Assets.safesecure} />
        </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default SafeSecure
