import React from 'react'
import { Assets } from '../Assets';
import { useState } from 'react';

function SuccessPopup({ closeSuccessPopup, handleClose, openLoginPopup, show, dataSuccess, setDtatSuccess, validatedAddTask,handleFormSubmitSuccess }) {
    const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';

    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const handleEmailChange = (e) => {
        setDtatSuccess({ ...dataSuccess, user_email: e.target.value });
      };
    
      const handlePhoneChange = (e) => {
        // Ensure that only digits are allowed and limit to 10 characters
        const phoneNumber = e.target.value.replace(/\D/g, '').slice(0, 10);
        setDtatSuccess({ ...dataSuccess, user_phone: phoneNumber });
      };
  return (

    <div className={ showHideClassName2 }>
    <section className="popup">
        <section className="popupInner login_signup success">

    <div className="contentFrameKywInner">
        <div className="kyw1BgImage">
            <img src={Assets.banner} alt="succcess" />
        </div>
        <div className="progressCirclesContainer">
        <div className=''>
        <img src={Assets.checked} alt="checked" />
        <h2>Laqia Account created</h2>
        <p style={{width:'80%'}}>Confirmation email and sms has been sent to your registered details</p>
        {/* <div className='d-flex content-btm'>
            <div className='content-btm-left'>
            <img src={Assets.qr} alt="QR" />
            </div>
            <div className='content-btm-right'>
                <h5>Download Our App</h5>
                <p>Discover the power of our menstrual app designed with you in mind.</p>
                <img src={Assets.app_store} alt="App store" />
                <img src={Assets.gp} alt="Google play" />
            </div>
        </div> */}
        <div className="downloadAppPopupContent">
            <div className="appScanner">
                <img src={ Assets.appQr } alt="Periodpal Mobile App" />
                <div className="appDownloadTextContent">
                    <h3 style={{textAlign:'left'}}>Download Our App</h3>
                    <p style={{textAlign:'left'}}>Discover the power of our menstrual app designed with you in mind.</p>
                    <div className="appDownloadLinks">
                        <a href="https://apps.apple.com/us/app/period-pal-by-laiqa/id1591718639">
                            <img src={ Assets.appStore } alt="Playstore" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.laiqa">
                            <img src={ Assets.playStore } alt="Playstore" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    </div>
    </section>
    <div id="rfwPopupCloseButton">
                        <button onClick={handleClose}>
                            <img src={ Assets.popupCloseCross } alt="Close" />
                        </button>
                    </div> 
    </section>
    </div>
  )
}

export default SuccessPopup
