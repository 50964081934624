import React, { Component } from 'react';

import NavbarBlogPage from './Blog/NavbarBlogPage';
import MainContentBlogList from './Blog/MainContentBlogList';
import BlogContentBlogPage from './Blog/BlogContentBlogPage';
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import NavbarHomePage from './Home/NavbarHomePage';
class Blog extends Component {
    render() {
        return(
            <div className="homePage blogpage">
                {/* <NavbarBlogPage /> */}
<NavbarHomePage />
                <MainContentBlogList />
                <BlogContentBlogPage />
                <div className="blogFooter">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default Blog;