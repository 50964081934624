import { Assets } from '../Assets';

import { Link } from 'react-router-dom';
import { useState } from 'react';

const MainContentWellnessPlans = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return ( 
        <div className="laiqaMainContentBg">
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img src={ Assets.laiqaMainContentMidLayerResp } id="wellnessPlansBanner1" />
                    {/* <img src={ Assets.laiqaMainContentMidLayerResp } id="wellnessPlansBanner2" /> */}
                </div>
                <div className="laiqaMainContentButtons">
                    <button id="midLayerButton"
                        className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        >
                        Book Now
                        {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpRightBlack } /> }
                    </button>
                    <div className="scheduleCallMainContent">
                        <div className='scheduleCallButtonWrapper'>
                            <Link to="/teleconsultation/select-specialist" style={{ textDecoration: "none" }}>
                                <button id="scheduleCallButtonHomePage"
                                    className={ `${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}` }
                                    onMouseEnter={() => setIsHovered2(true)}
                                    onMouseLeave={() => setIsHovered2(false)}
                                    >
                                    {isHovered2 ? <img src={ Assets.phoneCallingWhite } /> : <img src={ Assets.phoneCalling } /> }
                                </button>
                            </Link>
                        </div>
                        <div className="scheduleCallContent">
                            <p className="scheduleCallp1">Schedule Call</p>
                            <p className="scheduleCallp2">(+91) 73033 65564</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="breadcrumb">
                <div className="breadcrumbWrapper">
                    <div className="wpBreadcrumb">
                        <ul>
                            <li><Link to="/">Home </Link></li>
                            <li><Link to="/"> • Service • </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/wellness-plans"> Wellness Plans</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default MainContentWellnessPlans;