import React from "react";
import { Assets } from "../Assets";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeedbackSelectSpecialist = () =>{
    const testimonials_content = [
        {
            id:1,
            name:'Simran',
            description:"Joining Laiqa was the best choice I made. Their unique approach to women's wellness has completely changed my daily life. I'm confident that if I continue to follow Laiqa's advice, I'll keep experiencing these positive changes. Thank you, Laiqa, for all your support."
        },
        {
            id:2,
            name:'Shivani',
            description:"Laiqa's recommendations are truly amazing and seamlessly integrate into my daily routine. My PMS symptoms and menstrual flow have both shown remarkable improvement. I wholeheartedly recommend joining Laiqa. #FollowLaiqa. Thank you to the Laiqa team for their fantastic support!"
        },
        {
            id:3,
            name:'Riya',
            description:"Laiqa's recommendations have truly made a positive impact on my life. They've effectively helped me manage my PCOS symptoms, and I've experienced a significant improvement in my menstrual flow.I want to express my sincere gratitude to the Laiqa team."
        },
        {
            id:4,
            name:'Neha',
            description:"I can't thank the Laiqa team enough for their amazing support. Their approach to improving women's health has truly changed my life. After struggling with irregular periods for months, I followed their advice wholeheartedly.I'm so happy to say that, in a short time, my periods came back with a good flow. I'm confident that by sticking to Laiqa's recommendations, I'll have regular periods. Laiqa, you've made a real difference in my life!"
        }
    ];

    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 6000,
       // speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };
    return(
        <div className="feedbackContentWrapper">
            <div className="feedbackContentImageWrapper">
                <img src={ Assets.feedbackContentImage } />
            </div>
            <div className="feedbackContentTextWrapper">
                <div className="feedbackContentHeading1">
                    <p>TESTIMONIAL</p>
                </div>
                <div className="feedbackContentHeading2">
                    <p>What People Say</p>
                </div>
                <div className="feedbackContentHeading3">
                    <p>About Laiqa</p>
                </div>
                <div className="feedbackMessageContent">
                    <div className="feedbackRatingContent">
                        <img className="feedbackRatingContentQuotes" src={ Assets.feedbackQuotes } />
                        <img className="feedbackRatingContentStars" src={ Assets.feedbackRatingStars } />
                    </div>
                    <div className="feedbackMessage">
                    <Slider {...settings}>
                                {testimonials_content.map((item, i) => (
                                    <div key={i}>
                                    <div className="testimonials_card">
                                    <p>{item.description}</p>
                                    <h5 className="font-italic">{item.name}</h5>
                                    </div>
                                    </div>
                                    ))}
                                </Slider>    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackSelectSpecialist;