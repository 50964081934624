//import NavbarHomePage from "./Home/NavbarHomePage";
import MainContentCorporate from "./Corporate/MainContentCorporate";
import ExploreCorporate from "./Corporate/ExploreCorporate";
import WhyOnlyUsCorporate from "./Corporate/WhyOnlyUsCorporate";
import WcuTeleconsultatinPage from "./Teleconsultation/WcuTeleconsultationPage";
import OurDoctorsTeleconsultation from "./Teleconsultation/OurDoctorsTeleconsultation";
import FeaturedAndFooter from "./Home/FeaturedAndFooter";
import NavbarHomePage from "./Home/NavbarHomePage";
const Corporate = () => {
    return (
        <div className="corporatePage">
            <NavbarHomePage />
            <MainContentCorporate />
            <ExploreCorporate />
            <WhyOnlyUsCorporate />
            <div className="WcuCorporateWrapper">
                <WcuTeleconsultatinPage />
            </div>
            <div className="ourDoctorsCorporate">
                <OurDoctorsTeleconsultation />
            </div>
            <div className="corporateFooter1">
                <FeaturedAndFooter />
            </div>
        </div>  
    );
}

export default Corporate;