import { Assets } from "../Assets";

const HormonalImbalanceWellnessPlans = () =>{

    return(
        <div className="hormonalImbalance">
            <div className="hormonalImbalanceWrapper">
                <img alt="" src={ Assets.hormonalImbalanceBg } id="consultationContentBgImg1" />
                <img alt="" src={ Assets.hormonalImbalanceBgResp } id="consultationContentBgImg2" />
                <div className="hormonalImbalanceContentWrapper">
                    <div className="hormonalImbalanceTextContent">
                        <div className="hormonalImbalanceContentHeading">
                            <p className="hormonalDisorderHeading">Hormonal Disorder
                            <br/><b>Imbalance at a Glance/ Causes of Imbalance </b></p>
                        </div>
                        <div className="hormonalImbalanceContent">
                            {/* <p className="hormonalDisorderArticle">
                                Let's take a look at some conditions due to the often ignored hormonal imbalance over the last decade
                            </p> */}
                            <p className="hormonalDisorderArticle">A Quick Look, The consequences of hormonal imbalance, which have been frequently overlooked, need to be examined closely.</p>
                            <img alt="" src={ Assets.years } id="hormonalDisorderYears" />
                            <div className="hormonalImbalanceLabels">
                                <div className="hormonalImbalanceLabel1">
                                    <div className="menstrualSquare" />
                                    <p>58% Menstrual</p>
                                </div>
                                <div className="hormonalImbalanceLabel2">
                                    <div className="lifestyleSquare" />
                                    <p>42% Lifestyle</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hormonalImbalanceImageContent">
                        <img src={ Assets.bubbleStats } alt="Stats from 2013-2022" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HormonalImbalanceWellnessPlans;