import { Assets } from '../Assets';

import { useState } from 'react';

const AboutUsIntro = () => {

    const [isHovered, setIsHovered] = useState(false);

    return(
        <div className="aboutUsIntroContent">
            <div className="whyOnlyUsImageContent">
                <div className="aboutUsIntroImageWrapper">
                    <img src={ Assets.aboutUsIntroImage } />
                </div>
                <div className="aboutUsIntroTextContent">
                    <p className="workshopIntroTextHeading">About Us</p>
                    <p className='womenWellnessTextHeading2'>Empowering Feminine <br />Wellness through <b>Balance,<br /> You Deserve</b></p>
                    <p className="workshopIntroArticle">Welcome to LAIQA, where we are dedicated to revolutionizing overall well-being and menstrual health through personalized, subscription-based services. Our mission is to empower every woman on her journey to holistic wellness by focusing on the core of feminine health — hormonal balance. At LAIQA, we believe that every woman deserves to know herself and lead a balanced and healthy life.</p>
                </div>
            </div>
        </div>
    )
}

export default AboutUsIntro;