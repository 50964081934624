import { Assets } from '../Assets';

import { Link } from 'react-router-dom';
import { useState } from 'react';

const MissionWithTheWellnessPlans = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return ( 
        <div className="missionContent">
            <img className="whyOnlyUsImageBg1" src={ Assets.whyOnlyUsBg1 } />
            <div className="whyOnlyUsImageContent">
                <div className="missionContentImageWrapper">
                    <img src={ Assets.wellnessPlansMission } />
                </div>
                <div className="whyOnlyUsTextContent">
                <p className="whyOnlyUsTextHeading">About Wellness Plan</p>
                    {/* <p className="whyOnlyUsTextHeading">About Wellness Plan</p>
                    <p className='whyOnlyUsTextHeading2'>Mission with the</p>
                    <p className="whyOnlyUsTextHeading3">Wellness Plans</p>
                    <p className="whyOnlyUsTextArticle">Imagine a world where your wellness takes center stage. The Laiqa Plans are your gateway to that world, offering you a remarkable array of features that will empower you to take charge of your health and vitality. From personalized evaluations and expert insights to tailored diet and fitness plans, this plan is a holistic solution that caters to your unique needs.</p> */}
                    <p className='whyOnlyUsTextHeading2'>Achieving Optimal Wellness With </p> 
                    <p className="whyOnlyUsTextHeading3">Laiqa / Laiqa Wellness :</p>
                    <p className="whyOnlyUsTextArticle">The Path to a Healthier You imagine a World where your wellness is the sole focus. That is Laiqa for you! The Laiqa Plans provide you with an extraordinary range of features that will empower you to take charge of your health and vitality. From personalized assessments and expert guidance to customized diet and fitness plans, this holistic solution is tailored to meet your specific needs.</p>
                    <div className="laiqaMainContentButtons">
                        <Link to="/wellness-plans/menstrual-wellbeing-op1" style={{ textDecoration: "none" }}>
                            <button id="midLayerWellnessPlansButton2"
                                className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                >
                                Let's Start
                                {isHovered ? <img alt='' src={ Assets.arrowUpRight } /> : <img alt='' src={ Assets.arrowUpRightBlack } /> }
                            </button>
                        </Link>
                        <div className="scheduleCallWellnessPlans">
                            <div className='scheduleCallButtonWrapper'>
                                    <button id="scheduleCallButtonHomePage"
                                        className={ `${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}` }
                                        onMouseEnter={() => setIsHovered2(true)}
                                        onMouseLeave={() => setIsHovered2(false)} >
                                        {isHovered2 ? <img alt='' src={ Assets.phoneCallingWhite } /> : <img alt='' src={ Assets.phoneCalling } /> }
                                    </button>
                            </div>
                            <div className="scheduleCallContent">
                             <p className="scheduleCallp1">Schedule Call</p>
                             <p className="scheduleCallp2">(+91) 73033 65564</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <img alt='' className="whyOnlyUsImageBg2" src={ Assets.whyOnlyUsBg2 } />
            
        </div>
    );
}
 
export default MissionWithTheWellnessPlans;