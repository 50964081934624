import { Assets } from '../Assets';

import { Link } from 'react-router-dom';

import { useState } from 'react';
import { PostAPI } from '../utility/APiCall';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

const GetInTouchContactUs = () => {

    const [isChecked, setIsChecked] = useState(false);
    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };
    const [errors, setErrors] = useState({});
    // const [data, setData] = useState({
    //     full_name: "",
    //     email: "",
    //     mobile_number: "",
    //     message:"",
    //   });

    const initialData = {
        full_name: "",
        email: "",
        mobile_number: "",
        message:"",
    };
    const [data, setData] = useState(initialData);

      const validateFields = (name, value) => {
        const fieldErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mobilePattern = /^[0-9]{10}$/;
        //pattern="/^-?\d+\.?\d*$/"
    
        if (name === undefined || name === 'full_name') {
          if (!data.full_name) {
            fieldErrors.full_name = 'Full name is required';
          }
        }
        if (name === undefined || name === 'email') {
          if (!data.email) {
            fieldErrors.email = 'Email is required';
          } else if (!emailPattern.test(data.email)) {
            fieldErrors.email = 'Invalid email address';
          }
        }
        if (name === undefined || name === 'mobile_number') {
          if (!data.mobile_number) {
            fieldErrors.mobile_number = 'Mobile number is required';
          } else if (!mobilePattern.test(data?.mobile_number)) {
              //fieldErrors.mobile_number = 'Mobile number must be exactly 10 digits';
            //fieldErrors.mobile_number = 'Mobile number must be exactly 10 digits';
          }
        }
        if (name === undefined || name === 'message') {
          if (!data.message) {
            fieldErrors.message = 'Message is required';
          }
        }
        return fieldErrors;
      };

      // const handleInputChange = (e) => {
      //   const { name, value } = e.target;
    
      //   // Enforce numeric input for mobile_number
      //   if (name === 'mobile_number' && !/^[0-9]*$/.test(value)) {
      //     return; // Do nothing if the input is not numeric
      //   }
    
      //   // Trim value
      //   const trimmedValue = value.trim();
    
      //   // Enforce length limit for mobile number
      //   if (name === 'mobile_number' && trimmedValue.length > 10) {
      //     return; // Do nothing if length exceeds 10 digits
      //   }
    
      //   setData((prevData) => ({
      //     ...prevData,
      //     [name]: trimmedValue
      //   }));
    
      //   // Validate individual field
      //   const fieldErrors = validateFields(name, trimmedValue);
    
      //   setErrors((prevErrors) => {
      //     if (!Object.keys(fieldErrors).length) {
      //       const { [name]: _, ...rest } = prevErrors; // Remove specific error if the field is valid
      //       return rest;
      //     }
      //     return {
      //       ...prevErrors,
      //       ...fieldErrors
      //     };
      //   });
      // };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Enforce numeric input for mobile_number
        if (name === 'mobile_number' && !/^[0-9]*$/.test(value)) {
          return; // Do nothing if the input is not numeric
        }
        // Enforce length limit for mobile number
        if (name === 'mobile_number' && value.length > 10) {
          return; // Do nothing if length exceeds 10 digits
        }
        setData((prevData) => ({
          ...prevData,
          [name]: value
        }));
        // Validate individual field
        const fieldErrors = validateFields(name, value);
        setErrors((prevErrors) => {
          if (!Object.keys(fieldErrors).length) {
            const { [name]: _, ...rest } = prevErrors; // Remove specific error if the field is valid
            return rest;
          }
          return {
            ...prevErrors,
            ...fieldErrors
          };
        });
      };
    
    const handleSubmit = (event) => {
            console.log(event.target)
            const form = event.currentTarget
            event.preventDefault();
            event.stopPropagation();
            const validationErrors = validateFields();
            if (Object.keys(validationErrors).length === 0) {
              if (form.checkValidity()) {
                if(event.target.name == 'insert'){
                  var url = `/web/contact_us`;
                  const formatDate = new FormData();
                  formatDate.append('full_name', data?.full_name);
                  formatDate.append('email', data?.email);
                  formatDate.append('mobile_number', data?.mobile_number);
                  formatDate.append('message', data?.message);
                  var params = { url, data:formatDate }
                  console.log('params:', params);
                  PostAPI(params, (res) => {
                      if(res.status == 1){
                          console.log('API Response:', res);
                              toast.success(res?.message);
                              setData(initialData)
                              setTimeout(() => {
                              }, 2000); // Adjust the delay time (in milliseconds) as needed
                      }
                  });
                }
              }
            } else {
              setErrors(validationErrors);
            }
           
    }

    return (
      <div className="getInTouchWrapper">
        <div className="getInTouchContent">
          <div className="getInTouchHeading">
            <h1>Get in Touch</h1>
            <h6>
              Fill up the form and our team will get back to you within 24 hours
            </h6>
            <section className="getInTouchContentInner">
              <div className="getInTouchImageContent">
                <img
                  className="contactUsBgImage"
                  src={Assets.contactUsFormImage}
                  alt="Workshop"
                />
                <div className="getInTouchImageText">
                  <h1>You Can Reach Us</h1>
                  <div className="getInTouchImageTextItem">
                    <img src={Assets.contactUsLocation} alt="Location" />
                    <p>
                      101, Vatika Business Park, Tower - 2, Sohna Road
                      Sector-49, Gurgaon-122018, Haryana India
                    </p>
                  </div>
                  <div className="getInTouchImageTextItem">
                    <img src={Assets.contactUsPhone} alt="Phone" />
                    <p>+91 80765 81957</p>
                  </div>
                  <div className="getInTouchImageTextItem">
                    <img src={Assets.contactUsMail} alt="Email" />
                    <p>care@mylaiqa.com</p>
                  </div>
                  <div className="getInTouchSocialMedia">
                    <a href="https://www.twitter.com">
                      <img src={Assets.twitterWhite} alt="X" />
                    </a>
                    <a href="https://www.instagram.com">
                      <img src={Assets.instagramWhite} alt="Instagram" />
                    </a>
                    <a href="https://www.facebook.com">
                      <img src={Assets.facebookWhite} alt="Facebook" />
                    </a>
                    <a href="https://www.linkedin.com">
                      <img src={Assets.linkedInWhite} alt="LinkedIn" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="popupFormInner">
                <form
                  onSubmit={handleSubmit}
                  name={"insert"}
                  className="registerForWorkshopForm"
                >
                  <p id="corporateFormDesignation">Full Name*</p>
                  <input
                    type="text"
                    id="corporateFormDesignationInput"
                    // onChange={(e) =>
                    //   setData({ ...data, full_name: e.target.value })
                    // }
                    onChange={handleInputChange}
                    name='full_name'
                    value={data?.full_name}
                    placeholder="Enter your full name"
                    autocomplete="off"
                  />
                  {errors.full_name && <p className="error">{errors.full_name}</p>}
                  <p id="corporateFormCompany">Email</p>
                  <input
                    type="text"
                    id="corporateFormCompanyInput"
                    // onChange={(e) =>
                    //   setData({ ...data, email: e.target.value })
                    // }
                    onChange={handleInputChange}
                    name='email'
                    value={data?.email}
                    placeholder="Enter your email"
                    autocomplete="off"
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                  <div className='flag' style={{position:'relative'}}>
                  <p id="corporateFormMobileNumber">Mobile Number*</p>
                  <span style={{height:'28px', width:'28px',position:'absolute', left:'10px', top:'29px', display:'flex'}}>
                  <img style={{width:'100%'}} src={ Assets.indianFlag } alt="India" /> <span className='code'>+91</span>
                  {/* <p htmlFor="loginNumberId" style={{top:'8px'}}>+91</p> */}
                  </span>
                  <input
                    type="text"
                    id="corporateFormMobileNumberInput"
                    // onChange={(e) =>
                    //   setData({ ...data, mobile_number: e.target.value })
                    // }
                    onChange={handleInputChange}
                    name='mobile_number'
                    value={data?.mobile_number}
                    placeholder="Enter your mobile number"
                    pattern="[0-9]{10}"
                    autocomplete="off"
                  />
                  </div>
                  {errors.mobile_number && <p className="error">{errors.mobile_number}</p>}
                  <p id="corporateFormCountOfFemaleEmployees">Message</p>
                  <textarea
                    rows="2"
                    id="corporateFormFemaleEmployeeNumberInput"
                    // onChange={(e) =>
                    //   setData({ ...data, message: e.target.value })
                    // }
                    onChange={handleInputChange}
                    name='message'
                    value={data?.message}
                    style={{ paddingTop: "30px", resize: "none" }}
                    placeholder="Write your message"
                    autocomplete="off"
                  />
                  {errors.message && <p className="error">{errors.message}</p>}
                  <button type="submit" id="rfwRegisterFormButton">
                    Send Message
                  </button>
                </form>
              </div>
            </section>
          </div>
        </div>
        <div className="contactUsBottomBannerWrapper">
          <div className="contactUsBottomBanner">
            <img
              alt=""
              src={Assets.blogContentBanner}
              id="blogContentBottomBanner1"
            />
            <img
              alt=""
              src={Assets.blogContentBannerResp}
              id="blogContentBottomBanner2"
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
}
 
export default GetInTouchContactUs;