import React from 'react'
import { Assets } from '../Assets'

function CardInfoSelfPro() {
  return (
    <div className='main_grid'>
        <div className='parent_grid'>
            <div className='child_grid'>
              <img alt='women vesting their faith in us since 2019' src={Assets.Group_163701} />
              <h5>100k +</h5>
              <p>women vesting their faith in us since 2019</p>
            </div>
            <div className='child_grid'>
              <img alt='Ratings across all services' src={Assets.Group_1637012} />
              <h5>4.75 / 5</h5>
              <p>Ratings across all services</p>
            </div>
            <div className='child_grid'>
              <img alt='Health profile tracked across 90 parameters' src={Assets.Group_1637013} />
              <h5>90 Parameters</h5>
              <p>Health profile tracked across 90 parameters</p>
            </div>
        </div>
    </div>
  )
}

export default CardInfoSelfPro
