import React from "react";
import { Assets } from '../Assets'
import { useState } from 'react'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useNavigate } from "react-router-dom"

const ShopAndFeedback = () => {

    const testimonials_content = [
        {
            id:1,
            name:'Simran',
            description:"Joining Laiqa was the best choice I made. Their unique approach to women's wellness has completely changed my daily life. I'm confident that if I continue to follow Laiqa's advice, I'll keep experiencing these positive changes. Thank you, Laiqa, for all your support."
        },
        {
            id:2,
            name:'Shivani',
            description:"Laiqa's recommendations are truly amazing and seamlessly integrate into my daily routine. My PMS symptoms and menstrual flow have both shown remarkable improvement. I wholeheartedly recommend joining Laiqa. #FollowLaiqa. Thank you to the Laiqa team for their fantastic support!"
        },
        {
            id:3,
            name:'Riya',
            description:"Laiqa's recommendations have truly made a positive impact on my life. They've effectively helped me manage my PCOS symptoms, and I've experienced a significant improvement in my menstrual flow.I want to express my sincere gratitude to the Laiqa team."
        },
        {
            id:4,
            name:'Neha',
            description:"I can't thank the Laiqa team enough for their amazing support. Their approach to improving women's health has truly changed my life. After struggling with irregular periods for months, I followed their advice wholeheartedly.I'm so happy to say that, in a short time, my periods came back with a good flow. I'm confident that by sticking to Laiqa's recommendations, I'll have regular periods. Laiqa, you've made a real difference in my life!"
        }
    ];

    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        autoplay: true,
        fade:true,
        autoplaySpeed: 6000,
       // speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };

      const navigate = useNavigate();

    const imageItems = [
        { id: 1, cartSrc: Assets.shoppingCartWhite, cartHoverSrc: Assets.shoppingCartPink, viewSrc: Assets.shoppingCartViewImg, viewHoverSrc: Assets.shoppingCartViewImgWhite, productSrc: Assets.product2 },
        { id: 2, cartSrc: Assets.shoppingCartWhite, cartHoverSrc: Assets.shoppingCartPink, viewSrc: Assets.shoppingCartViewImg, viewHoverSrc: Assets.shoppingCartViewImgWhite,  productSrc: Assets.product3 },
        { id: 3, cartSrc: Assets.shoppingCartWhite, cartHoverSrc: Assets.shoppingCartPink, viewSrc: Assets.shoppingCartViewImg, viewHoverSrc: Assets.shoppingCartViewImgWhite, productSrc: Assets.product4 },
        { id: 4, cartSrc: Assets.shoppingCartWhite, cartHoverSrc: Assets.shoppingCartPink, viewSrc: Assets.shoppingCartViewImg, viewHoverSrc: Assets.shoppingCartViewImgWhite, productSrc: Assets.product5 },
        { id: 5, cartSrc: Assets.shoppingCartWhite, cartHoverSrc: Assets.shoppingCartPink, viewSrc: Assets.shoppingCartViewImg, viewHoverSrc: Assets.shoppingCartViewImgWhite, productSrc: Assets.product6 },
        { id: 6, cartSrc: Assets.shoppingCartWhite, cartHoverSrc: Assets.shoppingCartPink, viewSrc: Assets.shoppingCartViewImg, viewHoverSrc: Assets.shoppingCartViewImgWhite, productSrc: Assets.product7 },
    ];

    const pageLink = () =>{
        navigate(`https://mylaiqa.com/shop`);
        console.log('checking',navigate);
    }
    
    const ImageItem = ({ item }) => {
        const [isHovered, setHovered] = useState(false);
        const [isHovered2, setHovered2] = useState(false);
      
        return (
            <li>
                <div className="otherProductImageWrapper">
                    <img alt='' src={ item.productSrc } />
                </div>
                <div className="otherProductContentAndHoverWrapper">
                    <div className="otherProductContentWrapper">
                        <div className="otherProductName">
                            LAIQA Hormonal PCOS Tea 60gm
                        </div>
                        <div className="otherProductPrice">
                            &#8377; 329
                        </div>
                    </div>
                    <div className="otherProductHoverWrapper" >
                      <Link to="https://mylaiqa.com/shop">
                        <div className="otherProductViewButton">
                            <button onMouseEnter={() => setHovered2(true)} onMouseLeave={() => setHovered2(false)}>
                                <img alt='' src={ isHovered2 ? item.viewHoverSrc : item.viewSrc } />
                            </button>
                        </div>
                        <div className="otherProductAddToCartButton">
                            <button  onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                                <img alt='' src={ isHovered ? item.cartHoverSrc : item.cartSrc } />
                                <p> Add to Cart</p>
                            </button>
                        </div>
                       </Link>
                    </div>
                </div>
            </li>
        );
    }
    return ( 
        <>
            <div className="shopContent">
            <div className="shopContentBgImg">
                <img alt='' src={ Assets.shopAndFeedbackBg } />
            </div>
            <div className="shopContentWrapper">
                <div className="shopHeadingAndLinkContent">
                    <div className="shopContentHeading">
                        <p className="shopContentHeading1">Our Shop</p>
                        <p className="shopContentHeading2">Explore the Latest</p>
                        <p className="shopContentHeading3">Products in Store</p>
                    </div>
                    <div className="shopContentList">
                        <a href="https://mylaiqa.com/shop">Menstrual</a>
                        <a href="https://mylaiqa.com/shop">Lifestyle</a>
                        <Link to="https://mylaiqa.com/shop/">
                            <button>View all</button>
                        </Link>
                    </div>
                </div>
                <div className="shopProducts">
                    <div className="shopProductBig">
                        <div className="bigProductImageWrapper">
                            <img alt='' src={ Assets.bigProductImage } />
                        </div>
                        <div className="bigProductContentWrapper">
                            <div className="bigProductRating">
                                <div className="bigProductRatingWrapper">
                                    <img alt='' src={ Assets.ratingStar } />
                                    <p>4.5</p>
                                </div>
                                <p>1.5k Ratings</p>
                            </div>
                            <div className="bigProductName">
                                LAIQA Hormonal PCOS Tea 60gm
                            </div>
                            <div className="bigProductIngredients">
                                Green Tea, Shatavari, Spearmint
                            </div>
                            <div className="bigProductEndContent">
                                <div className="bigProductPrice">
                                    &#8377; 329
                                </div>
                                <Link to="https://mylaiqa.com/shop">
                                <div className="bigProductAddToCartButton">
                                    <button >
                                        <img alt='' src={ Assets.shoppingCartPink } />
                                        <p>Add to Cart</p>
                                    </button>
                                </div>
                               </Link>
                            </div>
                        </div>
                    </div>
                    <div className="shopProductsOther">
                        <ul className="shopProductsOtherUl">
                            {imageItems.map(item => (
                                <ImageItem key = {item.id} item = {item} />
                            ))}    
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
 
export default ShopAndFeedback;