import { Assets } from "../Assets"

import { useState } from 'react'
import {PostAPI, GetAPI, PatchAPI, DeleteAPI} from '../utility/APiCall' 
import { Link } from 'react-router-dom'

import PopupScheduleCall from '../ScheduleCallPopup/PopupScheduleCall'

const MainContentCorporate = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    const [showScheduleCallPopup, setShowScheduleCallPopup] = useState(false);

    const openScheduleCallPopup = () => {
        setShowScheduleCallPopup(true);
    };
    
    const closeScheduleCallPopup = () => {
        setShowScheduleCallPopup(false);
    };

    const [fullName, setFullName] = useState('');
    const [city, setCity] = useState('');
    const [designation, setDesignation] = useState('');
    const [company, setCompany] = useState('');
    const [nFemaleEmployees, setNFemaleEmployees] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    const handleRegistrationSubmit = async () => {

        if (!fullName || !city || !mobileNumber || !designation || !company || !nFemaleEmployees) {
            alert("Please fill in all the required fields before submitting the form.");
            return;
        }
    
        const isValidNumber = /^[0-9]{10}$/.test(mobileNumber);
        
        if (!isValidNumber) {
            alert("Please enter a valid mobile number");
            return;
        }
    
        const registrationData = {
            employee_name: fullName,
            designation: designation,
            company_name: company,
            city: city,
            female_employee_count: nFemaleEmployees,
            mobile_number: mobileNumber,
            user_id: 1
        };
    
        PostAPI({ url: '/web/corparate', data: registrationData }, (response) => {
            console.log('Registration Request:', registrationData);
            console.log('Registration Response:', response);
            if (response.status === 0 && response.message === "Already Registered User") {
                alert("You've already filled this form, thank you for choosing Laiqa!");
                window.location.reload();
            } 
            else {
                alert("Thank you for registering with Laiqa. Our team will reach out to you soon!")
                window.location.reload();
            }
        });
    };

    return ( 
        <div className="laiqaMainContentBg">
            <PopupScheduleCall show={ showScheduleCallPopup } handleClose={ closeScheduleCallPopup } openLoginOTPPopup={ openScheduleCallPopup } />
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img alt="" src={ Assets.corporateBannerImg } id="landingPageBannerImg1" />
                    {/* <img alt="" src={ Assets.corporateBannerImg } id="landingPageBannerImg2" /> */}
                </div>
                <div className="laiqaMainContentButtons">
                    <div className="scheduleCallCorporate">
                        <div className='scheduleCallButtonWrapper'>
                            <button id="scheduleCallButtonHomePage"
                                className={ `${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}` }
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}
                                onClick={openScheduleCallPopup} >
                                {isHovered2 ? <img alt="" src={ Assets.phoneCallingWhite } /> : <img src={ Assets.phoneCalling } /> }
                            </button>
                        </div>
                        <div className="scheduleCallContent">
                            <p className="scheduleCallp1">Schedule Call</p>
                            <p className="scheduleCallp2">(+91) 73033 65564</p>
                        </div>
                    </div>
                </div>
                <div className="corporateMainContentForm">
                    <div className="corporateMainContentFormInner">
                        <div className="formHeading">
                            <p>I would like to get for my employees :</p>
                        </div>
                        <form action="" className="corporateEmployeeDetailsForm">
                            <div className="formNameAndCityInputs">
                                <p id="corporateFormName">Full name*</p>
                                <input type="text" autocomplete="off" id="corporateFormNameInput" placeholder="Enter your name" onChange={(e) => setFullName(e.target.value)} />
                                <p id="corporateFormCity" style={{ marginLeft:"243px" }}>City*</p>
                                <input type="text" autocomplete="off" id="corporateFormCityInput" placeholder="Enter your city" onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <p id="corporateFormDesignation">Designation*</p>
                            <input type="text" autocomplete="off" id="corporateFormDesignationInput" placeholder="Enter your designation" onChange={(e) => setDesignation(e.target.value)} />
                            <p id="corporateFormCompany">Company*</p>
                            <input type="text" autocomplete="off" id="corporateFormCompanyInput" placeholder="Enter your company" onChange={(e) => setCompany(e.target.value)} />
                            <p id="corporateFormCountOfFemaleEmployees">Count of Female Employees*</p>
                            <input type="text" autocomplete="off" id="corporateFormFemaleEmployeeNumberInput" placeholder="Enter your count of female employees" onChange={(e) => setNFemaleEmployees(e.target.value)} />
                            <p id="corporateFormMobileNumber">Mobile Number*</p>
                            <input type="text" autocomplete="off" id="corporateFormMobileNumberInput" placeholder="Enter your mobile number" onChange={(e) => setMobileNumber(e.target.value)} />
                        </form>
                    </div>
                    <div className="requestOtpEmployeeDetailsForm">
                        <button id="couponSubmit" onClick={ handleRegistrationSubmit }>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default MainContentCorporate;