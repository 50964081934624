import React, { Component } from 'react';

import NavbarTeleconsultationPage from './Teleconsultation/NavbarTeleconsultationPage';
import MainContentTeleconsultationPage from './Teleconsultation/MainContentTeleconsultationPage';
import StepsTeleconsultationPage from './Teleconsultation/StepsTeleconsultationPage';
import FeedbackSelectSpecialist from './TeleconsSelectSpecialist/FeedbackSelectSpecialist';
import SearchSelectSpecialist from './TeleconsSelectSpecialist/SearchSelectSpecialist';
import OurDoctorsTeleconsultation from './Teleconsultation/OurDoctorsTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter'
import NavbarHomePage from './Home/NavbarHomePage';

class TeleconsultationSelectDoctor extends Component{
    render() {
        return (
            <div className="teleconsultationPageSelectDoctor">
            <NavbarHomePage />
                {/* <NavbarTeleconsultationPage /> */}
                <MainContentTeleconsultationPage />
                <SearchSelectSpecialist />
                <div className="stepsSelectSpecialist">
                  <StepsTeleconsultationPage />
                </div>
                <div className="feedbackContentSelectSpeccialist">
                  <FeedbackSelectSpecialist />
                </div>
                <div className="ourDoctorsTelecons2">
                  <OurDoctorsTeleconsultation />
                </div>
                <div className="teleconsFooter2">
                  <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default TeleconsultationSelectDoctor;