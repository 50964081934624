import { Assets } from "./Assets";

import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw12 from "./Kyw12/ContentFrameKyw12";
import FooterKyw from "./Kyw1/FooterKyw";
// import NavMenu from "./navbar/NavMenu";

const KnowYourWellness12 = () => {
    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            <NavbarKyw />
            <MainContentKyw />
            <ContentFrameKyw12 />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness12;