import React, { useState, useEffect } from 'react';
import { Assets } from '../Assets'
import {PostAPI, GetAPI, PatchAPI, DeleteAPI} from '../utility/APiCall' 
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ShopContentStorePage = () => {

    const testimonials_content = [
        {
            id:1,
            name:'Simran',
            description:"Joining Laiqa was the best choice I made. Their unique approach to women's wellness has completely changed my daily life. I'm confident that if I continue to follow Laiqa's advice, I'll keep experiencing these positive changes. Thank you, Laiqa, for all your support."
        },
        {
            id:2,
            name:'Shivani',
            description:"Laiqa's recommendations are truly amazing and seamlessly integrate into my daily routine. My PMS symptoms and menstrual flow have both shown remarkable improvement. I wholeheartedly recommend joining Laiqa. #FollowLaiqa. Thank you to the Laiqa team for their fantastic support!"
        },
        {
            id:3,
            name:'Riya',
            description:"Laiqa's recommendations have truly made a positive impact on my life. They've effectively helped me manage my PCOS symptoms, and I've experienced a significant improvement in my menstrual flow.I want to express my sincere gratitude to the Laiqa team."
        },
        {
            id:4,
            name:'Neha',
            description:"I can't thank the Laiqa team enough for their amazing support. Their approach to improving women's health has truly changed my life. After struggling with irregular periods for months, I followed their advice wholeheartedly.I'm so happy to say that, in a short time, my periods came back with a good flow. I'm confident that by sticking to Laiqa's recommendations, I'll have regular periods. Laiqa, you've made a real difference in my life!"
        }
    ];

    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 6000,
       // speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All"); 
    const [productsList, setProductsList] = useState([]);

    console.log('category List', categoryList);
    console.log('products List', productsList);


    const productList = () => {
        const url = `/web/get_category_wise_product_list?category=all`;
        GetAPI(url, (res) => {
            if (res.status === 1) {
                setCategoryList(res?.category_list);
                if (selectedCategory === "All") {
                    // If "All" is selected, set all products
                    const allProducts = res?.category_list.flatMap(category => category.product_list);
                    setProductsList(allProducts);
                } else {
                    // If a specific category is selected, filter products for that category
                    const selectedCategoryItem = res?.category_list.find(category => category.slug === selectedCategory);
                    setProductsList(selectedCategoryItem?.product_list || []);
                }
            }
        });
    }

    
    useEffect(()=>{
        productList();
    },[selectedCategory])

    const handleCategoryClick = (slug) => {
        setSelectedCategory(slug);
    }

    const pagelink = (link) => {
        // Open the link in a new tab
        window.open(link, '_blank');
    }

    const ImageItem = ({ item }) => {
        const [isHovered, setHovered] = useState(false);
        const [isHovered2, setHovered2] = useState(false);
        const [isHovered3, setHovered3] = useState(false);
        return (
            <li>
                <div className="shopProductBig">
                    <div className="otherProductContentAndHoverWrapper">
                        <div className="otherProductImageWrapper2">
                            <img alt={item?.title} src={ item.thumbnail } />
                        </div>
                        <div className="otherProductContentWrapper2">
                            <div className="bigProductRating">
                                <div className="bigProductRatingWrapper">
                                    <img alt="" src={ Assets.ratingStar } />
                                    <p>4.5</p>
                                </div>
                                <p>1.5k Ratings</p>
                            </div>
                            <div className="bigProductName">
                                {item?.title}
                            </div>
                            <div className="bigProductIngredients">
                               {item?.product_category}
                            </div>
                            <div className="bigProductEndContent">
                                <div className="bigProductPrice">
                                    &#8377;  {item?.price}
                                </div>
                            </div>
                        </div>
                        <div className="otherProductHoverWrapper2">
                            <div className="otherProductViewButton2">
                            <a href={item?.product_link} target='_blank' rel='noopener noreferrer'>
                            <button onMouseEnter={() => setHovered2(true)} onMouseLeave={() => setHovered2(false)}>
                            <img alt="" src={isHovered2 ? Assets?.shoppingCartViewImg : Assets?.shoppingCartViewImgWhite} />
                            </button>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }

    return ( 
        <div className="shopContent">
            <div className="teleconsMainContentBannerBottom">
                <div className="teleconsMainContentBannerBottomWrapper">
                    <img alt='' src={ Assets.storeMainContentBottomBanner } />
                </div>
            </div>
            <div className="shopContentWrapper">
                <div className="shopHeadingAndLinkContent2">
                    <div className="shopContentHeading">
                        <p className="shopContentHeading1">Our Shop</p>
                        <p className="shopContentHeading2">Let's Explore Latest <b>Products in Shop</b></p>
                    </div>
                    <div className="shopContentList2">
                <a onClick={() => handleCategoryClick("All")} className={selectedCategory === "All" ? "selected" : ""}>
                    All
                </a>
                {categoryList.map((item, i) => (
                    <a key={i} onClick={() => handleCategoryClick(item?.slug)} className={selectedCategory === item?.slug ? "selected" : ""}>
                        {item?.name}
                    </a>
                ))}
            </div>
                </div>
                <div className="shopProducts2">
                    <div className="shopProductsOther2">
                        <ul className="shopProductsOtherUl2">
                            {productsList.map(item => (
                                <ImageItem key = { item.id } item = {item} />
                            ))}    
                        </ul>
                    </div>
                </div>
                <div className="feedbackContentWrapper">
                    <div className="feedbackContentImageWrapper">
                        <img alt="" src={ Assets.feedbackContentImage } />
                    </div>
                    <div className="feedbackContentTextWrapper">
                        <div className="feedbackContentHeading1">
                            <p>Testimonial</p>
                        </div>
                        <div className="feedbackContentHeading2">
                            <p>What People Say</p>
                        </div>
                        <div className="feedbackContentHeading3">
                            <p>About Laiqa</p>
                        </div>
                        <div className="feedbackMessageContent">
                            <div className="feedbackRatingContent">
                                <img alt="" className="feedbackRatingContentQuotes" src={ Assets.feedbackQuotes } />
                                <img alt="" className="feedbackRatingContentStars" src={ Assets.feedbackRatingStars } />
                            </div>
                            <div className="feedbackMessage">
                            <Slider {...settings}>
                                {testimonials_content.map((item, i) => (
                                    <div key={i}>
                                    <div className="testimonials_card">
                                    <p>{item.description}</p>
                                    <h5 className="font-italic">{item.name}</h5>
                                    </div>
                                    </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shopContentWrapper">
                    <div className="shopHeadingAndLinkContent3">
                        <div className="shopContentHeading">
                            <p className="shopContentHeading1">You might also like</p>
                            <p className="shopContentHeading2"><b>Best Sellers</b></p>
                        </div>
                        <div className="shopContentList">
                            <a href="https://www.mylaiqa.com">
                                <button>View all</button>
                            </a>
                        </div>
                    </div>
                    <div className="shopProducts2">
                        <div className="shopProductBig2">
                            <div className="bigProductImageWrapper">
                                <img alt="" src={ Assets.bigProductImage } />
                            </div>
                            <div className="bigProductContentWrapper">
                                <div className="bigProductRating">
                                    <div className="bigProductRatingWrapper">
                                        <img alt="" src={ Assets.ratingStar } />
                                        <p>4.5</p>
                                    </div>
                                    <p>1.5k Ratings</p>
                                </div>
                                <div className="bigProductName">
                                    LAIQA Hormonal PCOS Tea 60gm
                                </div>
                                <div className="bigProductIngredients">
                                    Green Tea, Shatavari, Spearmint
                                </div>
                                <div className="bigProductEndContent">
                                    <div className="bigProductPrice">
                                        &#8377; 329
                                    </div>
                                    <div className="bigProductAddToCartButton">
                                        <button>
                                            <img alt="" src={ Assets.shoppingCartPink } />
                                            <p>Add to Cart</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shopProductBig2">
                            <div className="bigProductImageWrapper">
                                <img alt="" src={ Assets.bigProductImage } />
                            </div>
                            <div className="bigProductContentWrapper">
                                <div className="bigProductRating">
                                    <div className="bigProductRatingWrapper">
                                        <img alt="" src={ Assets.ratingStar } />
                                        <p>4.5</p>
                                    </div>
                                    <p>1.5k Ratings</p>
                                </div>
                                <div className="bigProductName">
                                    LAIQA Hormonal PCOS Tea 60gm
                                </div>
                                <div className="bigProductIngredients">
                                    Green Tea, Shatavari, Spearmint
                                </div>
                                <div className="bigProductEndContent">
                                    <div className="bigProductPrice">
                                        &#8377; 329
                                    </div>
                                    <div className="bigProductAddToCartButton">
                                        <button>
                                            <img alt="" src={ Assets.shoppingCartPink } />
                                            <p>Add to Cart</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shopProductBig2">
                            <div className="bigProductImageWrapper">
                                <img alt="" src={ Assets.bigProductImage } />
                            </div>
                            <div className="bigProductContentWrapper">
                                <div className="bigProductRating">
                                    <div className="bigProductRatingWrapper">
                                        <img alt="" src={ Assets.ratingStar } />
                                        <p>4.5</p>
                                    </div>
                                    <p>1.5k Ratings</p>
                                </div>
                                <div className="bigProductName">
                                    LAIQA Hormonal PCOS Tea 60gm
                                </div>
                                <div className="bigProductIngredients">
                                    Green Tea, Shatavari, Spearmint
                                </div>
                                <div className="bigProductEndContent">
                                    <div className="bigProductPrice">
                                        &#8377; 329
                                    </div>
                                    <div className="bigProductAddToCartButton">
                                        <button>
                                            <img alt="" src={ Assets.shoppingCartPink } />
                                            <p>Add to Cart</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ShopContentStorePage;