import { Assets } from "../Assets"

const PopupRegisterForWorkshopSuccessful = ({ handleClose, show }) => {
    
    const showHideClassName = show ? "workshopPopupBg display-block" : "workshopPopupBg display-none";

    return (
        <div className={ showHideClassName }>
            <section className="popup">
                <section className="popupInner">
                    <img src={ Assets.workshopPopupImage } alt="Workshop" />
                    <div className="popupFormInner">
                        <div className="rfwSuccessIcon">
                            <img src={ Assets.popupSuccessIcon } alt="Workshop Registration Success" />
                        </div>
                        <div className="rfwSuccessMessage">
                            <p>You have successfully registered for the workshop. Check your inbox for the workshop confirmation.</p>
                        </div>    
                        <button id="rfwRegisterFormButton" onClick={ handleClose }>
                            Okay
                        </button>
                    </div>
                    <div id="rfwPopupCloseButton">
                        <button onClick={ handleClose }>
                            <img src={ Assets.popupCloseCross } alt="Close" />
                        </button>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default PopupRegisterForWorkshopSuccessful;