import { Assets } from "./Assets";

import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw6 from "./Kyw6/ContentFrameKyw6";
import FooterKyw from "./Kyw1/FooterKyw";
import NavbarHomePage from "./Home/NavbarHomePage";
// import NavMenu from "./navbar/NavMenu";

const KnowYourWellness6 = () => {
    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img alt="" id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            {/* <NavbarKyw /> */}
            <NavbarHomePage/>
            <MainContentKyw />
            <ContentFrameKyw6 />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness6;