import React, { Component } from 'react';
import NavbarBlogPage from './Blog/NavbarBlogPage';
import MainContentBlogList from './Blog/MainContentBlogList';
import BlogItem1Content from './BlogItem1/BlogItem1Content';
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import { useParams } from "react-router-dom";
// import Footer from './Home/Footer';
import NavbarHomePage from './Home/NavbarHomePage';

const BlogItem1 = () => {
    const { id } = useParams();
  console.log('DDDDD',id);
    return (
      <div className="blogdetails homePage">
        {/* <NavbarBlogPage /> */}
        <NavbarHomePage />
        <MainContentBlogList />
        <BlogItem1Content id={id} />
        {/* <Footer /> */}
        <div className="blogFooter">
          <FeaturedAndFooter />
        </div>
      </div>
    );
  };
  
  export default BlogItem1;

// class BlogItem1 extends Component {
//     render() {
//         return(
//             <div className="homePage">
//                 <NavbarBlogPage />
//                 <MainContentBlogList />
//                 <BlogItem1Content id={id} />
//                 <div className="blogFooter">
//                     <FeaturedAndFooter />
//                 </div>
//             </div>
//         )
//     }
// }

// export default BlogItem1;