import React,{useState} from 'react';
import { Assets } from '../Assets';
import { PostAPI } from '../utility/APiCall';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PopupSignup from '../SignupPopup/PopupSignup';

const PopupLogin = ({ 
    handleClose, show, openLoginOTPPopup, validatedAddTask, isMobileConfirmed, setIsMobileConfirmed,
    otp, setOTP, data, setData, handleFormSubmit, getUID, setGetUID, showSignUpPopup, 
    setShowSignUpPopup, openSignUpPopup }) => {

    const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';
    const handlePhoneChange = (e) => {
        // Ensure that only digits are allowed and limit to 10 characters
        const phoneNumber = e.target.value.replace(/\D/g, '').slice(0, 10);
        setData({ ...data, phone: phoneNumber });
      };
    return (
        <div className={ showHideClassName2 }>
            <section className="popup">
                <section className="popupInner login_signup">
                    <img src={ Assets.loginPopupBg } alt="Login to Laiqa Wellness" />
                    <div className="popupFormInner">
                        <div className="checkoutLoginPhoneNumberHeading">
                            Enter your mobile number
                        </div>
                        <div className="checkoutLoginPhoneNumberHeading2">
                            You will be receiving an OTP
                        </div>
                    <form validated={validatedAddTask}
                    onSubmit={handleFormSubmit}
                    name={data?.id ? "update" : "insert"}>
                    <div className="loginFormWrapper" style={{position:'relative'}}>
                    <img style={{height:'28px', width:'28px',position:'absolute', left:'15px', top:'15px'}} src={ Assets.indianFlag } alt="India" />
                    <p htmlFor="loginNumberId" style={{top:'8px'}}>+91</p>
                    <input id="loginNumberId" type="tel" 
                    // onChange={(e) =>
                    // setData({ ...data, phone: e.target.value })
                    // }
                    onChange={handlePhoneChange}
                    value={data?.phone}
                    placeholder='Enter your mobile number' autocomplete="off" />
                    {/* onClick={ openLoginOTPPopup } */}
                    <button type='submit' id="loginPhoneNumberSubmit">
                    Request OTP
                    </button>
                    </div>
                    </form>
                    {/* <button onClick={openSignUpPopup} id="loginPhoneNumberSubmit">
                       Signup
                    </button> */}
                    <div className="termsAndConditionsText">
                        By continuing, you agree to the <br /> <b> <a style={{color:'#000', textDecoration:'none'}} href="https://mylaiqa.com/terms-and-conditions/">Terms & conditions</a> </b> and <b> <a style={{color:'#000', textDecoration:'none'}} href="https://mylaiqa.com/privacy-policy/">Privacy policy</a> </b> of Laiqa
                    </div>
                    </div>
                    <div id="rfwPopupCloseButton">
                        <button onClick={ handleClose }>
                            <img src={ Assets.popupCloseCross } alt="Close" />
                        </button>
                    </div>
                </section>
            </section>
            <ToastContainer />
        </div>
    );
}

export default PopupLogin;