import React, { Component, useEffect } from 'react';
import { Assets } from "./Assets"
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw1 from "./Kyw1/ContentFrameKyw1";
import FooterKyw from "./Kyw1/FooterKyw";
import NavbarHomePage from "./Home/NavbarHomePage";
import PopupLogin from './LoginPopup/PopupLogin';
import PopupLoginOTP from './LoginPopup/PopupLoginOTP'
import SuccessPopup from "./SignupPopup/SuccessPopup";
// import NavMenu from "./navbar/NavMenu";
const KnowYourWellness1 = (props) => {
     const {userName, userId} = props;
    // const userName = sessionStorage.getItem('user_name');
    // const getUID = sessionStorage.getItem('getUID');

    // const [getUID, setGetUID] = useState();
    // // console.log('getUID',getUID);

    // // sessionStorage.setItem('getUID',getUID);
    // const [userName, setUserName] = useCookie('user_name', '');
    // const [userId, setUserId] = useCookie('getUID', '');

    
    useEffect(() => {
        // Your effect code here
    }, [userName, userId]);

    return(
        <div>
            <NavbarHomePage userName={userName} userId={userId} />
            {/* <NavbarKyw  /> */}
            <MainContentKyw />
            <ContentFrameKyw1 />
            <FooterKyw />
            {/* <MainContentKyw />
            <ContentFrameKyw1 />
            <FooterKyw /> */}
        </div>
    )
}

export default KnowYourWellness1;