import React from "react";
import { Assets } from '../Assets'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StepsTeleconsultationPage = () => {

    const wellnessPlans_content = [
        {
            id:1,
            src:Assets.Group_164162,
        },
        {
            id:2,
            src:Assets.Group_164102,
        },
        {
            id:3,
            src:Assets.Group_164468,
        },
        {
            id:4,
            src:Assets.Group_164469,
        },
        {
            id:5,
            src:Assets.Group_164470,
        },
    ]

    const settings = {
        fade: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              fade: true,
              slidesToShow: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              fade: true,
              slidesToShow: 1,
              arrows: false,
            },
          },
        ],
      };
    return(
        <div className="stepsTeleconsWrapper">
        <Slider {...settings}>
            {wellnessPlans_content.map((item, i) => (
              <div key={i}>
                <div className="wellnessPlans_card">
                  <img alt={item.title} className="banner" src={item.src} />
    
                </div>
              </div>
            ))}
          </Slider>
            {/* <img id="step1" src={ Assets.teleconsStep1 } alt="Step 1" />
            <img id="step2" src={ Assets.teleconsStep2 } alt="Step 2" />
            <img id="step3" src={ Assets.teleconsStep3 } alt="Step 3" /> */}
        </div>
    )
}

export default StepsTeleconsultationPage;