import { Assets } from '../Assets';

import { useState } from 'react';
import { Link } from 'react-router-dom';

const KeyFeaturesAboutUs = () => {

    const imageItems = [
        { 
            id: 1,
            src: Assets.nutritionistWhite,
            hoverSrc: Assets.nutritionist, 
            alt: 'Personalized Wellness Plans',
            listContent: {
                listItem1: 'Tailored plans designed to address individual hormonal imbalances.',
                listItem2: 'Customized advice from our expert panel to promote overall well-being.'
            }
        },
        { 
            id: 2, 
            src: Assets.gynaecologistWhite, 
            hoverSrc: Assets.gynaecologist,
            alt: 'Subscription-Based Services',
            listContent: {
                listItem1: 'Access to a range of subscription plans catering to diverse wellness needs.',
                listItem2: 'Regular consultations to ensure continuous support on your wellness journey.',
                listItem3: 'Point of Care Diagnostics with app-based tools.'
            }
        },
        { 
            id: 3, 
            src: Assets.psychologistWhite,
            hoverSrc: Assets.psychologist,
            alt: 'Utilizing cutting-edge app-based diagnostics for convenient at-home testing.',
            listContent: {
                listItem1: 'Quick and accurate results to guide personalized wellness interventions.',
            }
        },
    ];

    const ImageItem = ({ item }) => {
        return (
            <li className="keyFeaturesListItem">
                <p className="keyFeaturesListHeading">
                    { item.alt }
                </p>
                <ul>
                    { item.listContent &&
                    Object.values(item.listContent).map((listItem, index) => (
                        <li key={ index }>
                        <p>{ listItem }</p>
                        </li>
                    ))}
                </ul>
            </li>
        );
    }

    return ( 
        <div className="keyFeaturesContent">
            <div className="consultationContentWrapper">
                <div className="consultationContentBgImg" style={{padding:'0px !important'}}>
                    <img src={ Assets.aboutUsKeyFeaturesBg } id="consultationContentBgImg1" />
                    {/* <img src={ Assets.consultationContentBgResp } id="consultationContentBgImg2" /> */}
                </div>
                <div className="consultationTextContent">
                    <div className="consultationContentHeading">
                        <p className="consultationHeading1" style={{ marginBottom:"20px" }}>Key Features</p>
                        <p className="consultationHeading2">Laiqa Provider Best</p>
                        <p className="consultationHeading3" style={{ color:"#D27060" }}>Female Wellbeing <br />App</p>
                    </div>
                </div>
                <div className="aboutUsKeyFeaturesContent">
                    <ul className="aboutUsKeyFeaturesWrapper">
                        {imageItems.map(item => (
                            <ImageItem key = { item.id } item = { item } />
                        ))}
                    </ul>
                </div>
            </div>
            <div className="aboutUsQuote">
                <div className="aboutUsQuoteWrapperOuter">
                    <div className="aboutUsQuoteWrapper">
                        <img src={ Assets.startQuotes } />
                        <p>"Balance You Deserve" encapsulates our commitment to helping women achieve and maintain hormonal balance. We believe that by embracing this balance, women can unlock their full potential, leading to a happier and healthier life</p>
                        <img src={ Assets.endQuotes } />
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default KeyFeaturesAboutUs;