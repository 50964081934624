import React, { Component } from 'react';

import NavbarWellnessPlans from './WellnessPlans/NavbarWellnessPlans';
import MainConentMenstrualWellbeing from './WellnessPlansMenstrualWellbeing/MainContentMenstrualWellbeing'
import PriceListingMenstrualWellbeing from './WellnessPlansMenstrualWellbeing/PriceListingMenstrualWellbeing';
import WcuWellnessPlansMenstrualWellbeing from './WellnessPlansMenstrualWellbeing/WcuWellnessPlansMenstrualWellbeing';
import OurDoctorsTeleconsultation from './Teleconsultation/OurDoctorsTeleconsultation';
import FAQWellnessPlansMenstrualWellbeing from './Teleconsultation/FAQTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter';
import NavbarHomePage from './Home/NavbarHomePage';
class WellnessPlansMenstrualWellbeing extends Component {
    render() {
        return(
            <div className="wellnessPlansIndex">
                {/* <NavbarWellnessPlans /> */}
                <NavbarHomePage />
                <MainConentMenstrualWellbeing />
                <PriceListingMenstrualWellbeing />
                <WcuWellnessPlansMenstrualWellbeing />
                <div className="ourDoctorsMenstrualWellbeingWrapper">
                    <OurDoctorsTeleconsultation />
                </div>
                <div className="FAQWellnessPlansMenstrualWellbeing">
                    <FAQWellnessPlansMenstrualWellbeing />
                </div> 
                <div className="wellnessFooter2">
                    <FeaturedAndFooter />
                </div>
            </div>
        )
    }
}

export default WellnessPlansMenstrualWellbeing;