import React,{useState, useEffect} from "react";
import { Assets } from "./Assets";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw5 from "./Kyw5/ContentFrameKyw5";
import FooterKyw from "./Kyw1/FooterKyw";
import { PostAPI, GetAPI } from "./utility/APiCall";
import { useNavigate } from "react-router-dom";
import useCookie from "./cookies/Cookie";
import Cookies from 'js-cookie';
import NavbarHomePage from "./Home/NavbarHomePage";
// import NavMenu from "./navbar/NavMenu";

const KnowYourWellness5 = () => {
    const navigate = useNavigate();

    const [questionId, setQuestionId] = useState([])

    const getCatId = sessionStorage.getItem('getMenstrualPain');
    console.log('getMenstrualPain', getCatId);

    const [category, setCategory] = useState({
        category_id:getCatId,
    });

    const get_questionnaires = (e) => {
      const getCatId = sessionStorage.getItem('getMenstrualPain');
         const categoryData = new FormData();
         categoryData.append('category_id', getCatId);
         //categoryData.append('category', "Sleep");
 
         PostAPI({ url: '/get_onboarding_questionnaires', data: categoryData }, (res) => {
             console.log('Questions', res?.data);
             const question_id = res?.data;
             console.log('question_id',question_id);
             setQuestionId(question_id);
         });
     }

useEffect(()=>{
    get_questionnaires();
},[]);

///get_user_plan_details/7
const [getPlan, setGetPlan] = useState();
const [journeyId, setJourneyId] =useState();
const [planDataId, setplanDataId] =useState();
const [month, setMonth] = useState();
console.log('getPlangetPlan', getPlan);

useEffect(() => {
  //const getUID = sessionStorage.getItem('getUID');
  const getUID = Cookies.get('getUID');
        console.log('getUID:', getUID);
  console.log('getUID:', getUID);
}, []);

const getPlandetails = () => {

  //const getUID = sessionStorage.getItem('getUID')
  const getUID = Cookies.get('getUID');
  const userId = getUID;
    //const url = `/get_user_plan_details/${userId}`;
    const url = `/v2_get_user_plan_details/${userId}`;

    GetAPI(url, (res)=>{
                const plan_data_id = res?.data?.plan_data_id;
                if (plan_data_id == null || plan_data_id == 0){
                setplanDataId(0);
                } else {
                setplanDataId(plan_data_id);
                }

                const pre_journey_id = res?.data?.pre_journey_id;
                if (pre_journey_id == null || pre_journey_id == 0){
                setJourneyId(0);
                } else {
                setJourneyId(pre_journey_id);
                }

                const current_month = res?.data?.plan_data_id;
                if (current_month == null || current_month == 0){
                setMonth(0);
                } else {
                setMonth(current_month);
                }
            const result = res?.data;
            setGetPlan(result);
    });
}

useEffect(()=>{
    getPlandetails();
}, [])

const [inputValue, setInputValue] = useState({});
const [isChecked, setIsChecked] = useState(false);
const [menstrulpain_scale, setSleep_scale] = useState([])
const [showLoginPopup, setShowLoginPopup] = useState(false);
const [errors, setErrors] = useState({});
const [currentStep, setCurrentStep] = useState(1);
const [formSubmitted, setFormSubmitted] = useState(false);
const openLoginPopup = () => {
    setShowLoginPopup(true);
    //closeLoginOTPPopup();
};

const closeLoginPopup = () => {
    setShowLoginPopup(false);
};

// const handleRadioChange = (questionId, optionId) => {
//   setInputValue(optionId);
// };

const handleRadioChange = (questionId, optionId) => {
    setInputValue((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: optionId,
    }));

    setData(prevData => ({
      ...prevData,
      answer: [
          ...prevData.answer.filter(ans => ans.question_id !== questionId),
          { question_id: questionId, option_id: optionId }
      ]
  }));

  // Remove error for the current question if answered
  setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[questionId];
      return newErrors;
  });

      // Check if any option is selected
    //   if (optionId !== null) {
    //     setIsChecked(true); // Set the flag to indicate that an option is selected
    // }
  };

  // const handleCheckboxChange = (questionId, optionId) => {
  //   setInputValue((prevAnswers) => {
  //     let updatedAnswers;
  
  //     if (questionId === "37") {
  //       // For question_id 37, handle the special case where optionId is an array of objects
  //       updatedAnswers = prevAnswers[questionId] || [];
  
  //       // Check if the optionId is already in the array
  //       const exists = updatedAnswers.some((ans) => ans.answer === optionId);
  
  //       // If the optionId is not in the array, add it
  //       if (!exists) {
  //         updatedAnswers.push({ answer: optionId });
  //       } else {
  //         // If the optionId is already in the array, remove it
  //         updatedAnswers = updatedAnswers.filter((ans) => ans.answer !== optionId);
  //       }
  //     } else {
  //       // For other questions, use the generic logic
  //       const currentAnswers = prevAnswers[questionId] || [];
  //       updatedAnswers = currentAnswers.includes(optionId)
  //         ? currentAnswers.filter((id) => id !== optionId)
  //         : [...currentAnswers, optionId];
  //     }
  
  //     return {
  //       ...prevAnswers,
  //       [questionId]: updatedAnswers,
  //     };
  //   });
  // };

  const handleCheckboxChange = (questionId, optionId) => {
    setInputValue((prevAnswers) => {
        let updatedAnswers;

        if (questionId === 37) {
            // Handle special case for question_id 37
            updatedAnswers = prevAnswers[questionId] || [];
            const exists = updatedAnswers.some((ans) => ans.answer === optionId);

            if (!exists) {
                updatedAnswers.push({ answer: optionId });
            } else {
                updatedAnswers = updatedAnswers.filter((ans) => ans.answer !== optionId);
            }
        } else {
            const currentAnswers = prevAnswers[questionId] || [];
            updatedAnswers = currentAnswers.includes(optionId)
                ? currentAnswers.filter((id) => id !== optionId)
                : [...currentAnswers, optionId];
        }

        setData(prevData => ({
            ...prevData,
            answer: [
                ...prevData.answer.filter(ans => ans.question_id !== questionId),
                { question_id: questionId, answer: questionId === 37 ? updatedAnswers : updatedAnswers.join(",") },
            ],
        }));

        console.log(`Updated answers for question ${questionId}:`, updatedAnswers);

        return {
            ...prevAnswers,
            [questionId]: updatedAnswers,
        };
    });
};

//   const handleCheckboxChange = (questionId, optionId) => {
//     setInputValue(prevAnswers => {
//         let updatedAnswers = prevAnswers[questionId] || [];

//         if (updatedAnswers.includes(optionId)) {
//             updatedAnswers = updatedAnswers.filter(id => id !== optionId);
//         } else {
//             updatedAnswers.push(optionId);
//         }

//         return {
//             ...prevAnswers,
//             [questionId]: updatedAnswers,
//         };
//     });
// };

const journey_id = sessionStorage.getItem('journey_id');

const [data, setData] = useState({
    user_id: '',
    category_id: getCatId,
    answer:[],
    journey_id: journey_id,
    plan_data_id: '0',
    month: '0',
  });
  const [questID, setQuestID] = useState([])


  const [getSleepData, setSleepData] = useState();
  const [message, setMessage ] = useState()
  const [getSleepscaleData, setSleepscaleData] = useState([]);

  //console.log('answer',answer);
  const handleFormSubmit = (e) => {
    e.preventDefault();
      // const answer = Object.entries(inputValue).map(([questionId, answer]) => ({
      //   question_id: questionId,
      //   answer:
      //     questionId === "37"
      //       ? Array.isArray(answer)
      //         ? answer.map((ans) => ({ answer: ans.answer }))
      //         : answer
      //           ? [{ answer: answer.answer }]
      //           : []
      //       : Array.isArray(answer)
      //       ? answer
      //       : answer
      //         ? answer
      //         : [],
      // }));

      const answer = Object.entries(inputValue).map(([questionId, answer]) => ({
        question_id: questionId,
        answer:
            questionId === "37"
                ? Array.isArray(answer)
                    ? answer.map(ans => ({ answer: ans.answer }))
                    : answer
                        ? [{ answer: answer.answer }]
                        : []
                : Array.isArray(answer)
                    ? answer
                    : answer
                        ? [answer]
                        : [],
    }));

    //   const answer = Object.entries(inputValue).map(([questionId, answer]) => ({
    //     question_id: questionId,
    //     answer: Array.isArray(answer) ? answer : [answer],
    // }));

   // const getUID = sessionStorage.getItem('getUID')
    const getUID = Cookies.get('getUID');
    const userId = getUID;
    const getCatId = sessionStorage.getItem('getMenstrualPain');
    const questionData = new FormData();
    questionData.append('user_id', userId);
    questionData.append('category_id', getCatId);
    questionData.append('answer', JSON.stringify(answer));
    questionData.append('journey_id', journeyId);
    questionData.append('plan_data_id', planDataId);
    questionData.append('month', month);
///add_onboarding_questionnaires_data
    PostAPI({ url: '/v2_add_onboarding_questionnaires_data', data: questionData }, (res) => {
        console.log('Questions 55', res?.data);
        if(res?.status == 1 ){
            const menstrulpain_scale = res?.scale;
            console.log('scale', menstrulpain_scale);
          //  const journey_id = res?.journey_id;
            const menstrulpain_score = res?.score;
           // sessionStorage.setItem('journey_id', journey_id);
            sessionStorage.setItem('menstrulpain_score', menstrulpain_score);
            sessionStorage.setItem('menstrulpain_scale', JSON.stringify(menstrulpain_scale)); 
            setSleep_scale(menstrulpain_score);
            setQuestID(res?.data);
            const sleep_scale = res?.scale;
            setSleepscaleData(sleep_scale);
            const sleep_score = res?.score;
            setSleepData(sleep_score);
            setFormSubmitted(true); // Update the state to mark form as submitted
          }
      }).catch((error) => {
        console.error('API Error:', error);
      });
}

const nextStep = () => {
  const steps = {
      1: [36],
      2: [37, 38, 39],
  };

  if (!steps[currentStep]) {
      console.error(`No steps defined for currentStep: ${currentStep}`);
      return;
  }

  const unansweredQuestions = questionId
      .filter(item => steps[currentStep].includes(item.question_id))
      .filter(item => !inputValue[item.question_id]);

  if (unansweredQuestions.length > 0) {
      const newErrors = {};
      unansweredQuestions.forEach(item => {
          newErrors[item.question_id] = "Please choose at least one.";
      });
      setErrors(newErrors);
      return;
  }

  setErrors({});
  setCurrentStep(prev => Math.min(prev + 1, 2));
};

    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img alt="" id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            {/* <NavbarKyw /> */}
            <NavbarHomePage />
            <MainContentKyw  />
            <ContentFrameKyw5 
                handleFormSubmit={handleFormSubmit}
                data={data} 
                setData={setData}
                handleRadioChange={handleRadioChange}
                handleCheckboxChange={handleCheckboxChange}
                questionId={questionId} 
                setQuestionId={setQuestionId}
                inputValue={inputValue} setInputValue={setInputValue}
                menstrulpain_scale={menstrulpain_scale} setSleep_scale={setSleep_scale}
                showLoginPopup={showLoginPopup} setShowLoginPopup={setShowLoginPopup}
                questID={questID} 
                setQuestID={setQuestID}
                getSleepscaleData={getSleepscaleData}
                setSleepscaleData={setSleepscaleData}
                getSleepData={getSleepData} 
                setSleepData={setSleepData}
                isChecked={isChecked} 
                setIsChecked={setIsChecked}
                errors={errors} 
                setErrors={setErrors}
                currentStep={currentStep}
                nextStep={nextStep}
                setCurrentStep={setCurrentStep}
                formSubmitted={formSubmitted}
                setFormSubmitted={setFormSubmitted}
              />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness5;