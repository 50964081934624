import React from "react";
import { Assets } from '../Assets'
import { useState } from 'react'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WellnessPlansScrollContent = () => {

    const wellnessPlans_content = [
        {
            id:1,
            src:Assets.banner01,
            title:"Laiqa- A One-stop Wellness Buddy / Wellness ",
            title_small:"Solution For All Your Needs ",
            description:"We recognize the significance of offering individualized support during your wellness journey. Our experienced team of wellness specialists is committed to being there for you at every step. We endeavor to provide comprehensive support that provides a seamless experience and your complete pleasure.",
        },
        {
            id:2,
            src:Assets.banner02,
            title:"Bid Goodbye to One-Size-Fits-All. Say Hello to ",
            title_small:"Plans Tailored Just For You!",
            description:"We acknowledge that your journey towards wellness is unique to you. That's why we have meticulously designed our plans to cater to your specific needs, ensuring that your path to wellness is as exceptional as you are.",
        },
        {
            id:3,
            src:Assets.banner03,
            title:"Expert Consultations ",
            title_small:"Anytime, Anywhere!",
            description:"We truly understand the importance of expert guidance. Our plans are designed to give you access to certified doctors, counsellors, and wellness experts who are dedicated to providing you with the necessary knowledge, support, and motivation at every stage of your journey.",
        },
        {
            id:4,
            src:Assets.banner04,
            title:"Unwavering ",
            title_small:"Support",
            description:"Embark on this transformative journey towards a better, more fulfilled version of yourself, and rest assured that we are here to support you every step of the way. What distinguishes us is our steadfast dedication to your overall wellness and the distinctive methods we employ to ensure your success.",
        },
        {
            id:5,
            src:Assets.banner05,
            title:"Empowerment and ",
            title_small:"Advancement",
            description:"Our focus goes beyond quick fixes; we are dedicated to creating long-term shifts. Our strategies provide you with the necessary tools, support, and expertise to implement impactful, enduring changes in your daily routine.",
        },
        {
            id:6,
            src:Assets.banner06,
            title:"Your Welltitleness; ",
            title_small:"Our Priority",
            description:"At Laiqa, we prioritize your well-being above all else. We are more than just a platform; we are dedicated partners in helping you achieve a life filled with energy and harmony.",
        }
    ]

    const settings = {
        fade: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              fade: true,
              slidesToShow: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              fade: true,
              slidesToShow: 1,
              arrows: false,
            },
          },
        ],
      };
    
    return (
      <div className="wellnessPlansScrollContent">
        <div className="laiqaMainContentWrapper">
          <Slider {...settings}>
            {wellnessPlans_content.map((item, i) => (
              <div key={i}>
                <div className="wellnessPlans_card">
                  <img alt={item.title} className="banner" src={item.src} />
                  <div className="card_details">
                    <h2>{item.title} <span>{item.title_small}</span></h2>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          {/* <div className="scrollImageWrapper2">
                    <img src={ Assets.wellnessPlansScroll1 } />
                    <img src={ Assets.wellnessPlansScroll2 } />
                    <img src={ Assets.wellnessPlansScroll3 } />
                    <img src={ Assets.wellnessPlansScroll4 } />
                    <img src={ Assets.wellnessPlansScroll5 } />
                    <img src={ Assets.wellnessPlansScroll6 } />
               </div> */}
        </div>
      </div>
    );
}

export default WellnessPlansScrollContent;