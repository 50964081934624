import React, { Component } from 'react';

import NavbarTeleconsultationPage from './Teleconsultation/NavbarTeleconsultationPage';
import MainContentTeleconsultationPage from './Teleconsultation/MainContentTeleconsultationPage';
import BookDetails from './TeleconsSelectSpecialist/BookDetails';
import NavbarHomePage from './Home/NavbarHomePage';
import SafeSecure from './TeleconsSelectSpecialist/SafeSecure';
import FAQTeleconsultation from './Teleconsultation/FAQTeleconsultation';
import FeaturedAndFooter from './Home/FeaturedAndFooter';
import OurTeamDoctor from './Teleconsultation/OurTeamDoctor';
class TeleconsultationConfirmBooking extends Component{
    render() {
        return (
          <div className="teleconsultationPageConfirmBooking bookspecialist">
            {/* <NavbarTeleconsultationPage /> */}
            <NavbarHomePage />
            <MainContentTeleconsultationPage />
            <div className="stepsSelectSpecialist">
              <BookDetails />
            </div>
            <div className='section_banner'>
             <SafeSecure />
            </div>
            <div className='section_faq'>
              <FAQTeleconsultation />
            </div>
            <div className='section_ourteam'>
              <OurTeamDoctor />
            </div>
            <div className='section_footer'>
              <FeaturedAndFooter />
            </div>
          </div>
        );
    }
}

export default TeleconsultationConfirmBooking;