import { Assets } from '../Assets';

import { useState } from 'react';

const FAQTeleconsultation = () => {

    //const [questionStates, setQuestionStates] = useState([false, false, false]);

    // const toggleQuestion = (index) => {
    //     const newQuestionStates = [...questionStates];
    //     newQuestionStates[index] = !newQuestionStates[index];
    //     setQuestionStates(newQuestionStates);
    // };
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
    const toggleQuestion = (index) => {
        if (activeQuestionIndex === index) {
            setActiveQuestionIndex(null); // Close the current question if it's already open
        } else {
            setActiveQuestionIndex(index); // Open the new question
        }
    };

    return (
        <div className="FAQContainer">
            <div className="FAQContainerInner">
                <div className="FAQHeading">
                    <p>Frequently <b>Asked Questions</b></p>
                </div>
                <div className="FAQAllQuestionsContainer">
                    <div 
                        // className={ `FAQItem ${questionStates[0] ? 'selectedQuestionItem' : ''}` }
                        // onClick={() => toggleQuestion(0)}
                        className={ `FAQItem ${activeQuestionIndex === 0 ? 'selectedQuestionItem' : ''}` }
                onClick={() => toggleQuestion(0)}
                        >
                        <div className="FAQQuestionContainer">
                            <div className="FAQQuestion">
                                1. How can I determine the right type of diet and exercise plan that suits my individual needs?
                            </div>
                            <div className="FAQAnswerExpandButton">
                                <img 
                                // src={ `${questionStates[0] ? Assets.minus : Assets.plus}` }
                                src={ `${activeQuestionIndex === 0 ? Assets.minus : Assets.plus}` }
                                alt="Expand" />
                            </div>
                        </div>
                        {activeQuestionIndex === 0 && (
                        <div className="FAQAnswer">
                          At Laiqa, we have tie-ups with expert healthcare providers, nutritionists, and fitness professionals to create personalized diet and exercise plans tailored to your specific hormonal health and wellness goals.
                        </div>
                    )}
                    </div>
                    <div 
                        // className={ `FAQItem ${questionStates[1] ? 'selectedQuestionItem' : ''}` }
                        // onClick={() => toggleQuestion(1)}
                        className={ `FAQItem ${activeQuestionIndex === 1 ? 'selectedQuestionItem' : ''}` }
                onClick={() => toggleQuestion(1)}
                        >
                        <div className="FAQQuestionContainer">
                            <div className="FAQQuestion">
                                2. In what ways can stress impact my hormonal health and overall well-being?
                            </div>
                            <div className="FAQAnswerExpandButton">
                                <img 
                                // src={ `${questionStates[1] ? Assets.minus : Assets.plus}` }
                                src={ `${activeQuestionIndex === 1 ? Assets.minus : Assets.plus}` }
                                alt="Expand" />
                            </div>
                        </div>
                        {activeQuestionIndex === 1 && (
                        <div className="FAQAnswer">
                          Chronic stress can disrupt your hormonal balance, leading to issues such as irregular periods, mood swings, weight gain, and compromised immune function.
                        </div>
                        )}
                    </div>
                    <div 
                        // className={ `FAQItem ${questionStates[2] ? 'selectedQuestionItem' : ''}` }
                        // onClick={() => toggleQuestion(2)}
                        className={ `FAQItem ${activeQuestionIndex === 2 ? 'selectedQuestionItem' : ''}` }
                        onClick={() => toggleQuestion(2)}
                        >                        
                        <div className="FAQQuestionContainer">
                            <div className="FAQQuestion">
                                3. What role do hormones play in the context of my overall health and wellness?
                            </div>
                            <div className="FAQAnswerExpandButton">
                                <img 
                                src={ `${activeQuestionIndex === 2 ? Assets.minus : Assets.plus}` }
                                // src={ `${questionStates[2] ? Assets.minus : Assets.plus}` } 
                                alt="Expand" />
                            </div>
                        </div>
                        {activeQuestionIndex === 2 && (
                        <div className="FAQAnswer">
                        Hormones play a crucial role in regulating various bodily functions in women, affecting mood, energy levels, metabolism, and reproductive health. At Laiqa, we provide you with personalized diet and fitness regimes curated just for you as our ultimate aim for you is to help you achieve hormonal harmony and optimal health.
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQTeleconsultation;