import { Assets } from "../Assets"

import { useState } from 'react';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ExploreCorporate = () => {

    const settings = {
        //centerMode: true,
       // centerPadding: '15px',
        slidesToShow: 3,
        //autoplay: false,
        //fade:true,
        autoplaySpeed: 6000,
       // speed: 500,
       
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };

    const imageItems = [
        { 
            id: 1,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
            blogImageSrc: Assets.wellnessPlans,
            blogHeading: "Wellness Plans",
            blogContent: "Wellness Plan is designed to help you improve your overall wellness. It allows you to work on resolving your health concerns from a position of strength. It is customized to help you manage your general wellbeing and achieve ultimate physical and mental health."
        },
        { 
            id: 2,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
            blogImageSrc: Assets.femaleWellnessProducts,
            blogHeading: "Feminine Wellness Products",
            blogContent: "LAIQA brings you high-end menstrual care products that are  eco-friendly, sustainable, comfortable, and suited for the modern you."
        },
        { 
            id: 3,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
            blogImageSrc: Assets.teleconsultation,
            blogHeading: "Teleconsultations",
            blogContent: "Book a consultation with our wellness expert now!"
        },
        { 
            id: 4,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
            blogImageSrc: Assets.askLaiqa,
            blogHeading: "Ask Laiqa",
            blogContent: "I am an intelligent chatbot, I am here to help you with everything you can ask me anything."
        },
    ];
    const ImageItem = ({ item }) => {
        const [isHovered, setHovered] = useState(false);
        const [isHovered2, setHovered2] = useState(false);
        return (
            <div className='card blshgcars'>
                <img src={ item.blogImageSrc } />
                <p className="blogContentItemsHeading">{ item.blogHeading }</p>
                <p className="blogContentItemsContent">{ item.blogContent }</p>
                <button id="blogContentReadMoreButton" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    Read More
                    <img src={ isHovered ? item.arrowHoverSrc : item.arrowSrc } />
                </button>
            </div>
        );
    }
    return ( 
        <div className="whyOnlyUsContent">
            <img className="corporateExploreImageBg" src={ Assets.whyOnlyUsBg1 } />
            <div className="corporateExploreContent">
                <div className="corporateHeadingContent">
                    <div className="blogContentHeading">
                        <p className="corporateExploreHeading1">Explore</p>
                        <p className="corporateExploreHeading2"><b>Our services</b> for knowing better</p>
                    </div>
                </div>
                <div id="scrollableDiv" className="corporateExploreContentItemsWrapper">
                    <ul className="corporateExploreContentItems_ blogContentItems_">
                        {/* {imageItems.map(item => (
                            <ImageItem key = { item.id } item = { item } />
                        ))} */}
                        <Slider {...settings}>
                      {imageItems?.slice(0, 10)?.map((item) => (
                      <ImageItem key={item.id} item={item} />
                      ))}
                      </Slider>
                    </ul>
                </div>
            </div>

            <div className="complianceContentExploreCorporate">
                <div className="complianceContentWrapper">
                    <div className="complianceContentHeading">
                        <div className="complianceContentHeading1">
                            Safe and Secure
                        </div>
                        <div className="complianceContentHeading2">
                            We are compliant to <b>Industry standards.</b>
                        </div>
                    </div>
                    <div className="complianceContentImage">
                        <img src={ Assets.complianceContentBanner } alt="Industry Standards" />
                    </div>
                </div>
            </div>           
        </div>
     );
}
 
export default ExploreCorporate;