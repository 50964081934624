import { Assets } from '../Assets';

import { useState } from 'react';
import {PostAPI, GetAPI, PatchAPI, DeleteAPI} from '../utility/APiCall' 

const PopupRegisterForWorkshop = ({ handleClose, show, openSuccessPopup, workshopId, workshopDate, workshopTime, isWorkshopActive }) => {
    
    const showHideClassName = show ? 'workshopPopupBg display-block' : 'workshopPopupBg display-none';

    const [isChecked, setIsChecked] = useState(true);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    const [errors, setErrors] = useState({});

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const validateFields = () => {
        const newErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mobilePattern = /^[0-9]{10}$/;

        if (!fullName) {
            newErrors.fullName = 'Full name is required';
        }
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!emailPattern.test(email)) {
            newErrors.email = 'Invalid email address';
        }
        if (!mobileNumber) {
            newErrors.mobileNumber = 'Mobile number is required';
        } else if (!mobilePattern.test(mobileNumber)) {
            newErrors.mobileNumber = 'Mobile number must be 10 digits';
        }
        return newErrors;
    };

    const handleRegistrationSubmit = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length === 0) {
        const registrationData = {
            workshop_id: workshopId,
            name: fullName,
            email: email,
            mobile_number: mobileNumber,
            workshop_slot: workshopDate + " " + workshopTime,
        };
    
        PostAPI({ url: '/web/workshop-registration', data: registrationData }, (response) => {
            console.log('Registration Request:', registrationData);
            console.log('Registration Response:', response);
            if (response.status === 0 && response.message === "Registration for this user is already complete.") {
                alert("You've already registered for this workshop, thank you for choosing LAIQA!");
            } 
            else {
                handleClose();
                openSuccessPopup();
            }
        });

    } else {
        setErrors(validationErrors);
      } 
    };

    const handleInputChange = (setter, field) => (e) => {
        setter(e.target.value);
        setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    };

    return (
        <div className={ showHideClassName }>
            <section className="popup">
                <section className="popupInner">
                    <img src={ Assets.workshopPopupImage } alt="Workshop" />
                    <div className="popupFormInner">
                        <div className="popupFormHeading">
                            <p>Register for the next workshop</p>
                        </div>
                        <form action="" className="registerForWorkshopForm">
                            <p id="corporateFormDesignation">Full Name*</p>
                            <input 
                                type="text" 
                                autoFocus
                                id="corporateFormDesignationInput" 
                                placeholder="Enter your full name"
                                //onChange={(e) => setFullName(e.target.value)} 
                                value={fullName}
                                onChange={handleInputChange(setFullName, 'fullName')} 
                                maxLength={40}
                                autocomplete="off"
                            />
                             {errors.fullName && <p className="error">{errors.fullName}</p>}
                            <p id="corporateFormCompany">Email</p>
                            <input 
                                type="text"
                                id="corporateFormCompanyInput"
                                placeholder="Enter your email"
                                // onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                onChange={handleInputChange(setEmail, 'email')}
                                maxLength={40}
                                autocomplete="off"
                            />
                             {errors.email && <p className="error">{errors.email}</p>}
                            <div className='flag' style={{position:'relative'}}>
                            <p id="corporateFormMobileNumber">Mobile Number*</p>
                  <span style={{height:'28px', width:'28px',position:'absolute', left:'10px', top:'29px', display:'flex'}}>
                  <img style={{width:'100%'}} src={ Assets.indianFlag } alt="India" /> <span className='code'>+91</span>
                  {/* <p htmlFor="loginNumberId" style={{top:'8px'}}>+91</p> */}
                  </span>
                            <input
                                type="text"
                                id="corporateFormMobileNumberInput"
                                placeholder="Enter your mobile number"
                                // onChange={(e) => setMobileNumber(e.target.value)}
                                value={mobileNumber}
                                onChange={handleInputChange(setMobileNumber, 'mobileNumber')}
                                maxLength={10}
                                autocomplete="off"
                            />
                            </div>
                             {errors.mobileNumber && <p className="error">{errors.mobileNumber}</p>}
                            <p id="corporateFormCountOfFemaleEmployees">Slot</p>
                            <input
                                type="text"
                                id="corporateFormFemaleEmployeeNumberInput"
                                placeholder="Select the Slot"
                                value={ workshopDate + " " + workshopTime }
                                autocomplete="off"
                            />
                             {/* {errors.email && <p className="error">{errors.email}</p>} */}
                        </form>
                        <div className={`rdwPopupCheck ${isChecked ? 'rdwPopupChecked' : ''}`}>
                            <input type="checkbox" checked={ isChecked } onChange={ toggleCheckbox } />
                            <span className="checkmarkEmpty"></span>
                            <img alt='' src={ Assets.check } className="checkmarkFilled"></img>
                            <p>I authorise LAIQA representatives to call and whatsapp me</p>
                        </div>
                        <button id="rfwRegisterFormButton" onClick={ handleRegistrationSubmit }>
                            Submit
                        </button>
                    </div>
                    <div id="rfwPopupCloseButton">
                        <button onClick={ handleClose }>
                            <img src={ Assets.popupCloseCross } alt="Close" />
                        </button>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default PopupRegisterForWorkshop;