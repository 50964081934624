import NavbarWorkshop from "./Workshop/NavbarWorkshop";
import MainContentWorkshop from "./Workshop/MainContentWorkshop";
import WorkshopIntro from "./Workshop/WorkshopIntro";
import RegisterForWorkshop from "./Workshop/RegisterForWorkshop";
import OurDoctorsTeleconsultation from "./Teleconsultation/OurDoctorsTeleconsultation";
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import NavbarHomePage from "./Home/NavbarHomePage";
import { useState } from 'react';

const Workshop = () => {

    const [isworkshopactive, setIsWorkshopActive] = useState(null);

    const handleActiveChange = (isActive) => {
        setIsWorkshopActive(isActive);
    };

    return(
        <div>
            {/* <NavbarWorkshop /> */}
            <NavbarHomePage />
            <MainContentWorkshop />
            <div className="workshopIntroContentWorkshop">
                <WorkshopIntro onActiveChange={ handleActiveChange } />
            </div>
            <RegisterForWorkshop onActiveChange={ handleActiveChange } />
            {/* <div className="ourDoctorsWorkshopWrapper" style={ isworkshopactive ? { marginTop:"2300px" } : { marginTop:"1400px" }}> */}
            <div className="ourDoctorsWorkshopWrapper" style={ isworkshopactive ? { marginTop:"40px" } : { marginTop:"40px" }}>
                <OurDoctorsTeleconsultation />
            </div>
            {/* <div className="workshopFooter1" style={ isworkshopactive ? { marginTop:"2952px" } : { marginTop:"2052px" }}> */}
            <div className="workshopFooter1" style={ isworkshopactive ? { marginTop:"0px" } : { marginTop:"0px" }}>
                <FeaturedAndFooter />
            </div>
        </div>
    )
}

export default Workshop;