import axios from 'axios';
const apiURL = process.env.REACT_APP_API_URL;
let API = axios;

console.log('apiURLBasedd', apiURL);

export const GetAPI = async (url, callback) => {
  await API.get(apiURL + url)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const GetAPI_body = async ({ url, data }, callback) => {
  await API.get(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const PostAPI = async ({ url, data }, callback) => {
  await API.post(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

// export const PostAPIRazer = async ({ url, data }, callback) => {
//   try {
//     const response = await API.post(apiURL + url, data);
//     callback(response?.data);
//   } catch (error) {
//     callback(error?.response?.data || { status: 0, message: "An error occurred" });
//   }
// };

export const PostAPIRazer = async ({ url, data }, callback) => {
  try {
    const response = await API.post(url, data);
    callback({ status: response.status, data: response.data });
  } catch (error) {
    const errorData = error?.response?.data || { status: 500, message: "An error occurred" };
    callback({ status: error?.response?.status || 500, data: errorData });
  }
};

export const PatchAPI = async ({ url, data }, callback) => {
  await API.put(apiURL + url, data)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const DeleteAPI = async ({ url, data }, callback) => {
  await API.delete(apiURL + url, { data: data })
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

export const DeleteAPI_params = async (url, callback) => {
  await API.delete(apiURL + url)
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      callback(error?.response?.data);
    });
};

