import { Assets } from '../Assets';
import { useState } from 'react';

const PopupSignup = ({ closeSignUpPopup, openLoginPopup, show, dataSignup, setDtatSignUp, validatedAddTask,handleFormSubmitSignup }) => {
    
    const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';

    const [isChecked, setIsChecked] = useState(true);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };
    
    const handleEmailChange = (e) => {
        const email = e.target.value;
        // Regular expression to match the email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        
        // Update the state with the entered email value
        setDtatSignUp({ ...dataSignup, user_email: email });
        
        // Provide feedback about invalid format if needed
        if (!emailRegex.test(email)) {
          // You can display an error message, show an indicator, etc.
          console.log("Invalid email format");
        }
      };
    
      const handlePhoneChange = (e) => {
        // Ensure that only digits are allowed and limit to 10 characters
        const phoneNumber = e.target.value.replace(/\D/g, '').slice(0, 10);
        setDtatSignUp({ ...dataSignup, user_phone: phoneNumber });
      };

      // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

    return (
        <div className={ showHideClassName2 }>
            <section className="popup">
                <section className="popupInner login_signup">
                    <img src={ Assets.loginPopupBg } alt="Workshop" />
                    <div className="popupFormInner">
                        <div className="popupFormHeading">
                            <p>Signup</p>
                        </div>
                            <form validated={validatedAddTask}
                            onSubmit={handleFormSubmitSignup}
                            name={dataSignup?.id ? "update" : "insert"} className="registerForWorkshopForm">
                            <p id="corporateFormDesignation">Full Name*</p>
                            <input type="text" id="corporateFormDesignationInput" 
                            onChange={(e) =>
                            setDtatSignUp({ ...dataSignup, user_name: e.target.value })
                            }
                            value={dataSignup?.user_name}
                            placeholder="Enter your full name" autocomplete="off" />
                            <p id="corporateFormCompany" >Email</p>
                            <input type="text" id="corporateFormCompanyInput" 
                            onChange={handleEmailChange}
                            // onChange={(e) =>
                            // setDtatSignUp({ ...dataSignup, user_email: e.target.value })
                            // }
                            value={dataSignup?.user_email}
                            placeholder="Enter your email" 
                            autocomplete="off" />
                            <p id="corporateFormCompany">Phone</p>
                            {/* <input type="text" id="corporateFormCompanyPhone"
                            onChange={handlePhoneChange} 
                            // onChange={(e) =>
                            // setDtatSignUp({ ...dataSignup, user_phone: e.target.value })
                            // }
                            value={dataSignup?.user_phone}
                            placeholder="Enter your phone number" /> */}
                            <p id="corporateFormCountOfFemaleEmployees">Date of Birth</p>
                            <input type="date" autocomplete="off" id="corporateFormFemaleEmployeeNumberInput" 
                            onChange={(e) =>
                            setDtatSignUp({ ...dataSignup, user_dob: e.target.value })
                            }
                            max={today}
                            value={dataSignup?.user_dob}
                            placeholder="Select date" />
                            <div className={`rdwPopupCheck ${isChecked ? 'rdwPopupChecked' : ''}`}>
                            <input type="checkbox" checked={ isChecked } onChange={ toggleCheckbox } />
                            <span className="checkmarkEmpty"></span>
                            <img alt='' src={ Assets.check } className="checkmarkFilled"/>
                            <p> I authorise LAIQA representatives to call and whatsapp me</p>
                        </div>
                        <button id="rfwRegisterFormButton" type='submit'>
                            Signup
                        </button>
                        </form>
                        {/* <button id="rfwRegisterFormButton" onClick={openLoginPopup}>
                            Login
                        </button> */}
                    </div>
                    {/* <div id="rfwPopupCloseButton">
                        <button onClick={openLoginPopup}>
                            <img src={ Assets.popupCloseCross } alt="Close" />
                        </button>
                    </div> */}
                </section>
            </section>
        </div>
    );
}

export default PopupSignup;