import React,{useState, useRef, useEffect} from 'react';
import { Assets } from "../Assets"
import { Link } from 'react-router-dom'
import { PostAPI } from '../utility/APiCall';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PopupLogin from '../LoginPopup/PopupLogin'

const PopupLoginOTP = ({ handleClose, show, openLoginPopup, openLoginOTPCheckPopup, data, getUID, setGetUID, 
    validatedAddTask, handleFormSubmitotp, handleBackspace, otp, setOtp, isFormVisible, 
    setFormVisible, isOtpComplete, setIsOtpComplete, otpInputs, inputRefs, handleChange, handleFormSubmit, 
    setData, isMobileConfirmed, setIsMobileConfirmed, closeLoginPopup, openSignUpPopup, openLoginOTPPopup }) => {
    
    const showHideClassName2 = show ? "loginPopupBg display-block" : "loginPopupBg display-none";
    useEffect(() => {
        if (show){
            setFormVisible(true);
        }
    }, [show]);

    const [isOpen, setIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showSignUpPopup, setShowSignUpPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showLoginOTPPopup, setShowOTPPopup] = useState(false);

    // const openLoginPopup = () => {
    //     setShowLoginPopup(true);
    //     setShowSignUpPopup(false);
    //     setShowOTPPopup(false)
    //     closeLoginPopup();
    //     //alert('check')
    // };
    //  const openLoginOTPPopup = () => {
    //     setShowOTPPopup(true);
    //     closeLoginPopup();
    // };

    const phone = sessionStorage.getItem('phone');
    const lastFourDigits = phone ? phone.slice(-4) : '';
    return (
        <div className={ showHideClassName2 }>
         <PopupLogin />
            <section className="popup">
                <section className="popupInner login_signup">
                    <img src={ Assets.loginPopupBg } alt="Workshop" />
                    <div className="popupFormInner">
                        <div className="checkoutLoginPhoneNumberHeading">
                            Verify your Number
                        </div>
                        <div className="checkoutLoginPhoneNumberHeading2">
                            +91 *******{lastFourDigits} 
                            {/* - <b onClick={openLoginOTPCheckPopup}>Change</b> */}
                        </div>
                        <form validated={validatedAddTask}
                        onSubmit={handleFormSubmitotp}
                        name={data?.id ? "update" : "insert"}>
                        <div className="otpFormWrapper">
                        {otpInputs?.map((index) => (
                        <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                        handleBackspace(index);
                        }
                        }}
                        ref={inputRefs[index]}
                        />
                        ))}
                        </div>
                        <button type='submit' id="otpSubmit"
                        className={isOtpComplete ? 'enabled-otp-submit-button' : 'disabled-otp-submit-button'}
                        // disabled={otp?.join('').length < 6}
                        // onClick={ handleClose }
                        >
                        Submit
                        </button>
                        </form>
                        <div className="termsAndConditionsText">
                            By continuing, you agree to the <br /> <b>terms & conditions</b> and <b>privacy policy</b> of Laiqa
                        </div>
                    </div>
                    <div id="rfwPopupCloseButton">
                        <button onClick={ handleClose }>
                            <img src={ Assets.popupCloseCross } alt="Close" />
                        </button>
                    </div>
                </section>
            </section>
            <ToastContainer/>
        </div>
    );
}

export default PopupLoginOTP;